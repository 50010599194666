/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import DocumentTitle from "react-document-title";
import {
  Grid,
  Container,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import LinkingListContainer from "../../components/LinkingList";

const useStyles = makeStyles((theme) => ({
  mainBackground: {
    background: theme.palette.background.mainBg,
    minHeight: "calc(100% - 64px)",
  },
}));

function LinkingList() {
  const classes = useStyles();

  return (
    <DocumentTitle title="Linking List">
      <Grid container spacing={0} className={classes.mainBackground}>
        <Container maxWidth={false}>
          <Box mt={3} mb={3}>
            <LinkingListContainer />
          </Box>
        </Container>
      </Grid>
    </DocumentTitle>
  );
};

export default LinkingList;
