// @flow
import React from "react";
import environment from "environment";
import type { Url, CollapseItem, Breadcrumb } from "@fas/ui-core/lib/AppNavbar";
import { AppNavbar } from "@fas/ui-core";
import { createSearchParams, useLocation } from "react-router-dom";
import { userApiService } from "../../services/usersApi/users";
import getBreadcrumbs from "./breadcrumbsMap";
import appLinks from "./appLinks";
import AccountProfile from "./AccountProfile";
import { loginUrl } from "../../services/request";

type Props = {
  apiKey: string | null,
};

function AppHeader({ apiKey }: Props) {
  const location = useLocation();
  const hashId = createSearchParams(location.search).get("hashId");
  const isHashExist: boolean = !!location.hash;
  const menuLinks: Array<Url | CollapseItem> = [
    ...((isHashExist || hashId) ? [
      {
        url: `${environment.links.documentList}?hashId=${hashId}`,
        text: "Pages",
      }, {
        url: `${environment.links.redirectList}?hashId=${hashId}`,
        text: "Redirects",
      }, {
        url: `${environment.links.linkingList}?hashId=${hashId}`,
        text: "Linking",
      },
    ] : []),
  ];

  const breadcrumbs: Breadcrumb[] = getBreadcrumbs(location);

  const handleLogout: () => void = () => {
    userApiService.logout(environment.endpoints.get.logoutUrl)
      .then(() => {
        window.location.href = loginUrl;
      });
  };

  return (
    <AppNavbar
      appName="Jump Service"
      menuLinks={menuLinks}
      appLinks={appLinks}
      breadcrumbs={breadcrumbs}
      onLogout={handleLogout}
      customComponent={<AccountProfile apiKey={apiKey} />}
      useHistory
    />
  );
};

export default AppHeader;
