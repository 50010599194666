// @flow
import React, {useState} from "react";
import {Avatar, Box, Button, Menu, MenuItem, Typography} from "@mui/material";
import {ArrowDropDown} from "@mui/icons-material";

type AccountErrorProps = {
  handleShowJUSAccounts: (SyntheticEvent<HTMLButtonElement>) => void,
  handleChangeJUSAccount: (SyntheticEvent<HTMLButtonElement>) => void,
}
function AccountError({handleShowJUSAccounts, handleChangeJUSAccount}: AccountErrorProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Avatar>E</Avatar>
      <Box ml={2}>
        <Typography >Account error!</Typography>
        <Typography>You don`t have access or are using the wrong account.</Typography>
      </Box>
      <Button onClick={handleClick}>
        <ArrowDropDown sx={{color: "#fff"}}/>
      </Button>
      <Menu
        open={open}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        <MenuItem onClick={handleShowJUSAccounts} disableRipple>
          Show list of JUS accounts
        </MenuItem>
        <MenuItem onClick={handleChangeJUSAccount} disableRipple>
          Change Jus account
        </MenuItem>
      </Menu>
    </>
  );
}

export default AccountError;
