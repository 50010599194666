// @flow
import React from "react";
import type { TextField as TextFieldType } from "@mui/material";
import {
  Box, Button, FormControl, FormHelperText, InputAdornment, TextField, Tooltip, Autocomplete,
} from "@mui/material";
import { getErrorMessage, hasError } from "@fas/ui-framework/lib/services/generators";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";

export type Props = {
  anchor: string,
  description: string,
  type: string,
  types: Array<string>,
  errors: ErrorsMapper,
  onAnchorChange: (string) => mixed,
  onTypeChange: (string) => mixed,
  onDescriptionChange: (string) => mixed,
  onCancel: () => mixed,
  onApply: () => mixed,
}

function ModifierForm({
  anchor,
  description,
  type,
  types,
  errors,
  onAnchorChange,
  onTypeChange,
  onDescriptionChange,
  onCancel,
  onApply,
}: Props) {
  return <>
    <FormControl fullWidth error={hasError("modifierCreateFormName", errors)}>
      <TextField
        label="Anchor"
        margin="normal"
        variant="outlined"
        size="small"
        placeholder="test_modifier"
        value={anchor}
        error={hasError("modifierCreateFormName", errors)}
        onChange={({ target: { value } }) => onAnchorChange(value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title="It's the anchor(name) of your modifier.">
                <HelpOutlineIcon />
              </Tooltip>
            </InputAdornment>
          ),
          "data-testid": "anchor",
        }}
      />
      <Box ml={2}>
        <FormHelperText>{getErrorMessage("modifierCreateFormName", errors)}</FormHelperText>
      </Box>
    </FormControl>
    <FormControl
      fullWidth
      variant="outlined"
      error={hasError("modifierCreateFormType", errors)}
    >
      <Autocomplete
        id="type"
        options={types}
        value={type}
        getOptionLabel={(x) => x}
        onChange={(_, value) => onTypeChange(value)}
        renderInput={(params): TextFieldType => (
          <TextField
            {...params}
            label="Type"
            variant="outlined"
            margin="normal"
            size="small"
            placeholder="Select the type of modifier"
            error={hasError("modifierCreateFormType", errors)}
            InputProps={{
              "data-testid": "type",
              ...params.InputProps,
              startAdornment: (
                <Box pl={1}>
                  <InputAdornment position="start">
                    <Tooltip title="The type of your modifier.">
                      <HelpOutlineIcon />
                    </Tooltip>
                  </InputAdornment>
                </Box>
              ),
            }}
          />
        )}
      />
      <FormHelperText>{getErrorMessage("modifierCreateFormType", errors)}</FormHelperText>
    </FormControl>
    <FormControl fullWidth error={hasError("modifierCreateFormDescription", errors)}>
      <TextField
        multiline
        label="Description"
        margin="normal"
        variant="outlined"
        size="small"
        placeholder="This modifier is for..."
        value={description}
        error={hasError("modifierCreateFormDescription", errors)}
        onChange={({ target: { value } }) => onDescriptionChange(value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title="It's the description(explanation) of your modifier.">
                <HelpOutlineIcon />
              </Tooltip>
            </InputAdornment>
          ),
          "data-testid": "description",
        }}
      />
      <Box ml={2}>
        <FormHelperText>{getErrorMessage("modifierCreateFormDescription", errors)}</FormHelperText>
      </Box>
    </FormControl>
    <Box display="flex" justifyContent="flex-end">
      <Box pr={1}>
        <Button data-testid="cancelButton" onClick={onCancel}>Cancel</Button>
      </Box>
      <Button data-testid="applyButton" onClick={onApply} color="primary" variant="contained">Add New Modifier</Button>
    </Box>
  </>;
}

export default ModifierForm;
