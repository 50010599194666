// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import UserApi from "@fas/ui-framework/lib/services/userApi";
import { requestService } from "../request";
import { userService } from "../user";

export const getUsersList = (searchStr?: string, limit?: number): PromiseResponse<mixed> => requestService
  .get(environment.endpoints.get.usersList, {
    params: {
      searchStr,
      limit,
    },
  }).then((result) => result.data.data.usersList.map((i) => ({ label: i.username, value: i.id})));

export const updateUserRole = (userId: string, role: number): PromiseResponse<mixed> => requestService
  .put(environment.endpoints.put.users.replace("{id}", userId), { role });

// eslint-disable-next-line import/prefer-default-export
export const userApiService: UserApi = new UserApi(userService, requestService);
