// @flow
import React from "react";
import {Box, Skeleton} from "@mui/material";

function AccountSkeleton() {
  return (
    <>
      <Skeleton variant="circular" width={40} height={40} sx={{backgroundColor: "rgb(190 194 205 / 80%)"}}/>
      <Box ml={2}>
        <Skeleton variant="text" width={50} height={19} sx={{backgroundColor: "rgb(190 194 205 / 80%)"}}/>
        <Skeleton variant="text" width={150} height={19} sx={{backgroundColor: "rgb(190 194 205 / 80%)"}}/>
      </Box>
    </>
  );
}

export default AccountSkeleton;
