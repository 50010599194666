// @flow
import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {getAccount} from "../../services/accountApi/account";
import AccountSkeleton from "./AccountSkeleton";
import AccountData from "./AccountData";
import AccountError from "./AccountError";
import useHashId from "../../hooks/useHashId";

type Props = {
  apiKey: string | null,
};
function AccountProfile({apiKey}: Props) {
  const params = useLocation();
  const [accountNameLoading, setAccountNameLoading] = useState(false);
  const [accountName, setAccountName] = useState("");
  const navigate = useNavigate();
  const { hashId,removeHashId } = useHashId();

  const handleShowJUSAccounts = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate("/");
  };
  const handleChangeJUSAccount = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    removeHashId();
    navigate("/");
  };
  useEffect(() => {
    if (!apiKey || !hashId) return;
    const getAcc = async () => {
      setAccountNameLoading(true);
      try {
        const { data } = await getAccount(hashId);
        const acc = data.data.account;
        if(acc) {
          setAccountName(acc.name);
        }
        setAccountNameLoading(false);
      }
      catch(err) {
        removeHashId();
        navigate("/");
      }
    };
    getAcc();

  }, [apiKey, hashId]);

  return (
    <Box display="flex" p={2}>
      {(!apiKey || accountNameLoading) && hashId && (
        <AccountSkeleton />
      )}
      {(apiKey && !accountNameLoading) && (hashId && accountName) && (
        <AccountData
          pathname={params.pathname}
          accountName={accountName}
          handleShowJUSAccounts={handleShowJUSAccounts}
          handleChangeJUSAccount={handleChangeJUSAccount}
        />
      )}
      {(apiKey && !accountNameLoading) && (hashId && !accountName) && (
        <AccountError
          handleShowJUSAccounts={handleShowJUSAccounts}
          handleChangeJUSAccount={handleChangeJUSAccount}
        />
      )}
    </Box>
  );
}

export default AccountProfile;
