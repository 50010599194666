// @flow
import React from "react";
import { Provider } from "react-redux";
import { Outlet } from "react-router-dom";
import configureStore from "./store";
import AppContainer from "../../containers/AppStoreWrapper";
import NotificationsContainer from "../../components/NotificationsContainer/NotificationsContainer";

let store;
function AppStoreWrapper() {
  if(!store) {
    store = configureStore();
  }

  return(
    <Provider store={store}>
      <AppContainer>
        <NotificationsContainer />
        <Outlet />
      </AppContainer>
    </Provider>
  );
}

export default AppStoreWrapper;
