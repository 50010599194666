// @flow
import { Record } from "immutable";
import type { RecordOf, RecordFactory } from "immutable";
import {
  CHANGE_PAGE_NAME,
  CHANGE_PAGE_TYPE,
  SET_PAGE,
  SET_PAGE_VERSION,
  CHANGE_PAGE_URL,
  CHANGE_PAGE_PFT_TEMPLATE,
  CHANGE_PAGE_TAGS,
} from "../../helpers/constants";
import type {
  Actions,
  ChangePageNameAction,
  ChangePageTypeAction,
  ChangePageUrlAction,
  ChangePagePftTemplateAction,
  SetPageVersionAction,
} from "../../actions/pageInfo";
import type {
  IncomingPageModifiersState,
} from "../pageModifiers";
import type {
  VersionType,
  IncomingPageVersionState,
} from "../pageVersions";



export type PageType = "page" | "template" | "pft" | "custom";

export type DefaultStateType = {
  pageId: string,
  name: string,
  pageType: PageType,
  versionId: string,
  versionType: VersionType,
  tags: Array<string>,
  url: string,
  template: {
    templateId: string,
    templateName: string
  },
}

const defaultState: DefaultStateType = {
  pageId: "",
  versionId: "",
  versionType: "new",
  name: "",
  pageType: "page",
  tags: [],
  url: "",
  template: { templateId: "", templateName: "" },
};

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export type State = RecordOf<DefaultStateType>;

export const initialState: State = makeState({});

export const initPageInfoState: (pageInfo?: State) => State = (pageInfo?: State): State => pageInfo || initialState;

type IncomingTag = {|
  accountId: string,
  id: string,
  tag: string,
|}

type IncomingAlias = {
  hash: string,
  alias: string,
}

export type IncomingPageInfoState = {|
  documentId: string,
  parentId?: string,
  name: string,
  type: PageType,
  tagCollection: Array<IncomingTag>,
  modifierCollection?: Array<IncomingPageModifiersState>,
  versionCollection: Array<IncomingPageVersionState>,
  aliasCollection: Array<IncomingAlias>,
|}

// eslint-disable-next-line default-param-last
export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case CHANGE_PAGE_NAME: {
      const { name }: ChangePageNameAction = action;
      return state.set("name", name);
    }
    case CHANGE_PAGE_TYPE: {
      const { pageType }: ChangePageTypeAction = action;
      return state.set("pageType", pageType);
    }
    case CHANGE_PAGE_URL: {
      const { url }: ChangePageUrlAction = action;
      return state.set("url", url);
    }
    case CHANGE_PAGE_PFT_TEMPLATE: {
      const { templateId, templateName }: ChangePagePftTemplateAction = action;
      return state.withMutations((newState: State) => {
        newState.setIn(["template", "templateId"], templateId);
        newState.setIn(["template", "templateName"], templateName);
      });
    }
    case SET_PAGE: {
      const { payload }: { payload: IncomingPageInfoState } = action;
      const {
        documentId,
        name,
        type,
        tagCollection,
        aliasCollection,
      }: IncomingPageInfoState = payload;

      return state.withMutations((newState: State) => {
        newState.set("pageId", documentId);
        newState.set("name", name);
        newState.set("pageType", type);
        newState.updateIn(["tags"], (
          tags: Array<string>
        ): Array<string> => [...tags, ...tagCollection.map(({ tag }: IncomingTag): string => tag)]);
        aliasCollection && aliasCollection.length > 0 && newState.set("url", aliasCollection[0].alias);
      });
    }
    case SET_PAGE_VERSION: {
      const { versionType, versionId }: SetPageVersionAction = action;
      return state.withMutations((newState: State) => {
        newState.set("versionType", versionType);
        newState.set("versionId", versionId);
      });
    }
    case CHANGE_PAGE_TAGS: {
      const { tags } = action;
      return state.set("tags", tags);
    }

    default:
      return state;
  }
};
