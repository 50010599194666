// @flow
import React from "react";
import type { Node as ReactNode } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Container,
} from "@mui/material";
import { Loader } from "@fas/ui-core";

interface Props {
  open: boolean,
  loading: boolean,
  hideApply: boolean,
  title: string,
  children?: ReactNode,
  onApply: () => void,
  onClose: () => void,
}

function FormDialog({
  open,
  loading,
  hideApply,
  title,
  children = null,
  onApply,
  onClose,
}: Props) {


  const handleClose = (event, reason) => {
    if (reason === "backdropClick" && loading) {
      return;
    }
    onClose();
  };
  return (
    <Dialog data-testid="form-dialog" fullWidth open={open} disableEscapeKeyDown={loading} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent id="form-dialog-content">
        <Loader loading={loading}>
          <Container maxWidth="sm">
            { children }
          </Container>
        </Loader>
      </DialogContent>
      <DialogActions>
        <Button data-testid="cancel-button" disabled={loading} onClick={() => onClose()} color="primary">
          CANCEL
        </Button>
        {!hideApply && (
          <Button data-testid="apply-button" disabled={loading} onClick={() => onApply()} color="primary">
            APPLY
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

FormDialog.defaultProps = {
  open: false,
  loading: false,
  hideApply: false,
  title: "",
  onApply: () => {},
};

export default FormDialog;
