// @flow
import React from "react";
import { SetupCard, ComboBox } from "@fas/ui-core";
import {
  Grid,
  Box,
  Typography,
  FormControl,
  TextField,
} from "@mui/material";
import { hasError, getErrorMessage } from "@fas/ui-framework/lib/services/generators";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";

type Props = {
  pageUrl: string,
  statusCode: string,
  statusCodesList: Array<string>,
  redirectUrl: string,
  errors: ErrorsMapper,
  onChangePageUrl: (pageUrl: string) => void,
  onChangeStatusCode: (statusCode: string) => void,
  onChangeRedirectUrl: (redirectUrl: string) => void,
}

function RedirectlInfo({
  pageUrl,
  statusCode,
  statusCodesList,
  redirectUrl,
  errors,
  onChangePageUrl,
  onChangeStatusCode,
  onChangeRedirectUrl,
}: Props) {
  return <SetupCard title="General Info">
    <>
      <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
        <Grid item xs={3}>
          <Typography>Page URL</Typography>
        </Grid>
        <Grid item xs={9}>
          <Box width={1} mb={2}>
            <FormControl variant="filled" fullWidth>
              <TextField
                id="mui-textfield-redirect-page-url"
                variant="outlined"
                size="small"
                value={pageUrl}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>): void => onChangePageUrl(e.target.value)}
                error={hasError("pageUrl", errors)}
                helperText={hasError("pageUrl", errors) && getErrorMessage("pageUrl", errors)}
                data-testid="redirect-page-url-text-field"
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
        <Grid item xs={3}>
          <Typography>Status Code</Typography>
        </Grid>
        <Grid item xs={9}>
          <Box width={1} mb={2}>
            <FormControl variant="filled" fullWidth>
              <ComboBox
                id="mui-autocomplete-redirect-status-code"
                label="Type"
                options={statusCodesList}
                value={statusCode}
                defaultValue={statusCode}
                isOptionEqualToValue={(option: string, val: string): boolean => option === val}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    data-testid="mui-autocomplete-redirect-status-code"
                    variant="outlined"
                    error={hasError("statusCode", errors)}
                    helperText={hasError("statusCode", errors) && getErrorMessage("statusCode", errors)}
                    fullWidth
                  />
                )}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>, value: string): void => onChangeStatusCode(value)}
                disableClearable
                data-testid="redirect-status-code-combobox"
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
        <Grid item xs={3}>
          <Typography>Redirect URL</Typography>
        </Grid>
        <Grid item xs={9}>
          <Box width={1} mb={2}>
            <FormControl variant="filled" fullWidth>
              <TextField
                id="mui-textfield-redirect-redirect-url"
                variant="outlined"
                size="small"
                value={redirectUrl}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>): void => onChangeRedirectUrl(e.target.value)}
                error={hasError("redirectUrl", errors)}
                helperText={hasError("redirectUrl", errors) && getErrorMessage("redirectUrl", errors)}
                data-testid="redirect-redirect-url-text-field"
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  </SetupCard>;
}

export default RedirectlInfo;
