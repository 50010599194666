// @flow
/* eslint-disable import/max-dependencies */
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";

import type { Page} from "../../components/PagesList/types/PagesList.types";
import type { State as PageInfoState } from "../../reducers/pageInfo";
import type { State as PageVersionsState, Version, VersionType } from "../../reducers/pageVersions";
import type { State as PageModifiersState } from "../../reducers/pageModifiers";
import type { State as PageResourcesState, Resource } from "../../reducers/pageResources";

import type { State } from "../../pages/AppStoreWrapper";
import type {State as PagesListState} from "../../reducers/pages";

import { makeModifier } from "../../reducers/pageModifiers/reducer";
import type { Modifier } from "../../reducers/pageModifiers/reducer";
import { initPageModifiersState } from "../../reducers/pageModifiers";
import type {State as LoadingState} from "../../reducers/loading";

const pageListSelector = (state: State): PagesListState => state.page.pagesList;
const pageInfoSelector = (state: State): PageInfoState => state.page.pageInfo;

export const getPageList: OutputSelector<State, *, Array<Page>
> = createSelector(
  pageListSelector,
  (pagesList: PagesListState): Array<Page> => pagesList.get("data")
);

export const getPageInfo: OutputSelector<State, *, PageInfoState> = createSelector(
  pageInfoSelector,
  (pageInfo: PageInfoState): PageInfoState => pageInfo
);

export const getPageId: OutputSelector<State, *, string> = createSelector(
  pageInfoSelector,
  (pageInfo: PageInfoState): string => pageInfo.get("pageId")
);

export const getPageVersionId: OutputSelector<State, *, string> = createSelector(
  pageInfoSelector,
  (pageInfo: PageInfoState): string => pageInfo.get("versionId")
);

export const getPageType: OutputSelector<State, *, string> = createSelector(
  pageInfoSelector,
  (pageInfo: PageInfoState): string => pageInfo.get("pageType")
);

export const getPageCurrentVersionType: OutputSelector<
  State,
  *,
  VersionType
> = createSelector(
  pageInfoSelector,
  (pageInfo: PageInfoState): VersionType => pageInfo.get("versionType")
);

export const getPageVersions: OutputSelector<State, *, PageVersionsState> = createSelector(
  (state: State): PageVersionsState => state.page.pageVersions,
  (pageVersions: PageVersionsState): PageVersionsState => pageVersions
);

export const getPageVersionByType: OutputSelector<State, *, Version> = createSelector(
  (
    state: State,
    versionType: VersionType
  ): Version => state.page.pageVersions.get(versionType),
  (pageVersions: Version): Version => pageVersions
);

export const getDocumentModifiers: OutputSelector<State, *, Array<Modifier>> = createSelector(
  (state: State): PageModifiersState => state.page.pageModifiers,
  (pageModifiers: PageModifiersState): Array<Modifier> => pageModifiers.get("allIds")
    .map((key: string): Modifier => pageModifiers.getIn(["byId", key], makeModifier()).toObject()).toArray()
);

export const getDefaultModifiersName: () => Array<string> = () => initPageModifiersState().get("allIds")
  .map((key: string): string => initPageModifiersState().getIn(["byId", key, "name"], "")).toArray();

export const getPageResources: OutputSelector<State, *, Array<Resource>> = createSelector(
  (state: State): PageResourcesState => state.page.pageResources,
  (pageResources: PageResourcesState): Array<Resource> => pageResources.get("resources")
);

export const getPagesListLoading: OutputSelector<State | State, *, boolean> = createSelector(
  (state: State): LoadingState<string> => state.loading,
  (loading: LoadingState<string>): boolean => loading.get("pagesListLoading", false)
);
