// @flow
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import { savePageSaga } from "../../actions/page";
import { getIsSavePageLoading } from "../../selectors/loading";

export default function CodeEditorActionButtons() {
  const dispatch = useDispatch();
  const isSavePageLoading = useSelector(getIsSavePageLoading);

  const onSavePage = useCallback((options) => {
    dispatch(savePageSaga(options));
  }, [dispatch]);

  return (
    <>
      <IconButton
        data-testid="preview-fullscreen-btn"
        color="inherit"
        disabled={isSavePageLoading}
        onClick={() => onSavePage({ isPreviewEnabled: true })}
      >
        <VisibilityIcon />
      </IconButton>
      <IconButton
        data-testid="save-fullscreen-btn"
        color="inherit"
        disabled={isSavePageLoading}
        onClick={onSavePage}
      >
        <SaveIcon />
      </IconButton>
    </>
  );
};
