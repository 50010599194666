// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import { List } from "immutable";
import type {State as EditAccountState} from "../../pages/AppStoreWrapper";
import type { User } from "../../pages/EditAccount";
import type { DefaultState } from "../../reducers/accountInfo/reducer";

export const getGeneralInfo: OutputSelector<EditAccountState, *, DefaultState> = createSelector(
  (state) => state.account.accountInfo,
  (accountInfo) => accountInfo.toObject()
);

export const getUsers: OutputSelector<EditAccountState, *, Array<User>> = createSelector(
  (state) => state.account.accountUsers.get("users"),
  (users: List<User>) => users.toArray()
);
