// @flow
export const GET_PAGES_LIST_SAGA: "GET_PAGES_LIST_SAGA" = "GET_PAGES_LIST_SAGA";
export const SET_PAGES_LIST: "SET_PAGES_LIST" = "SET_PAGES_LIST";
export const SET_PAGES_LIST_COUNT: "SET_PAGES_LIST_COUNT" = "SET_PAGES_LIST_COUNT";
export const ADD_SELECTED_PAGE: "ADD_SELECTED_PAGE" = "ADD_SELECTED_PAGE";
export const REMOVE_SELECTED_PAGE: "REMOVE_SELECTED_PAGE" = "REMOVE_SELECTED_PAGE";
export const CLONE_PAGE_SAGA: "CLONE_PAGE_SAGA" = "CLONE_PAGE_SAGA";
export const OPEN_CLONE_PAGE_DIALOG: "OPEN_CLONE_PAGE_DIALOG" = "OPEN_CLONE_PAGE_DIALOG";
export const CLOSE_CLONE_PAGE_DIALOG: "CLOSE_CLONE_PAGE_DIALOG" = "CLOSE_CLONE_PAGE_DIALOG";
export const EXPORT_PAGE_SAGA: "EXPORT_PAGE_SAGA" = "EXPORT_PAGE_SAGA";
export const OPEN_EXPORT_PAGE_DIALOG: "OPEN_EXPORT_PAGE_DIALOG" = "OPEN_EXPORT_PAGE_DIALOG";
export const CLOSE_EXPORT_PAGE_DIALOG: "CLOSE_EXPORT_PAGE_DIALOG" = "CLOSE_EXPORT_PAGE_DIALOG";
export const IMPORT_PAGE_SAGA: "IMPORT_PAGE_SAGA" = "IMPORT_PAGE_SAGA";
export const OPEN_IMPORT_PAGE_DIALOG: "OPEN_IMPORT_PAGE_DIALOG" = "OPEN_IMPORT_PAGE_DIALOG";
export const CLOSE_IMPORT_PAGE_DIALOG: "CLOSE_IMPORT_PAGE_DIALOG" = "CLOSE_IMPORT_PAGE_DIALOG";
export const PAGES_LIST_BULK_DELETE_SAGA: "PAGES_LIST_BULK_DELETE_SAGA" = "PAGES_LIST_BULK_DELETE_SAGA";
export const ACTIVATE_DOCUMENT_SAGA: "ACTIVATE_DOCUMENT_SAGA" = "ACTIVATE_DOCUMENT_SAGA";
export const CHANGE_DOCUMENT_VERSION_STATUS:"CHANGE_DOCUMENT_VERSION_STATUS" = "CHANGE_DOCUMENT_VERSION_STATUS";
export const ARCHIVE_PAGE_SAGA:"ARCHIVE_PAGE_SAGA" = "ARCHIVE_PAGE_SAGA";
export const PAGES_LIST_SET_PAGE:"PAGES_LIST_SET_PAGE" = "PAGES_LIST_SET_PAGE";
export const PAGES_LIST_SET_PAGE_SIZE:"PAGES_LIST_SET_PAGE_SIZE" = "PAGES_LIST_SET_PAGE_SIZE";
export const PAGES_LIST_SET_SORTING:"PAGES_LIST_SET_SORTING" = "PAGES_LIST_SET_SORTING";
export const PAGES_LIST_SET_FILTERS:"PAGES_LIST_SET_FILTERS" = "PAGES_LIST_SET_FILTERS";
export const CHANGE_PAGE_TAGS:"CHANGE_PAGE_TAGS" = "CHANGE_PAGE_TAGS";

export const SET_BACKUP: "SET_BACKUP" = "SET_BACKUP";
export const CLEAR_BACKUP: "CLEAR_BACKUP" = "CLEAR_BACKUP";
export const RESTORE_BACKUP: "RESTORE_BACKUP" = "RESTORE_BACKUP";

export const SET_ERRORS: "SET_ERRORS" = "SET_ERRORS";

export const CHANGE_PAGE_NAME: "CHANGE_PAGE_NAME" = "CHANGE_PAGE_NAME";
export const CHANGE_PAGE_TYPE: "CHANGE_PAGE_TYPE" = "CHANGE_PAGE_TYPE";
export const CHANGE_PAGE_URL: "CHANGE_PAGE_URL" = "CHANGE_PAGE_URL";
export const CHANGE_PAGE_PFT_TEMPLATE: "CHANGE_PAGE_PFT_TEMPLATE" = "CHANGE_PAGE_PFT_TEMPLATE";

export const GET_PAGE_SAGA: "GET_PAGE_SAGA" = "GET_PAGE_SAGA";
export const SET_PAGE: "SET_PAGE" = "SET_PAGE";
export const SET_PAGE_VERSION: "SET_PAGE_VERSION" = "SET_PAGE_VERSION";
export const SAVE_PAGE_SAGA: "SAVE_PAGE_SAGA" = "SAVE_PAGE_SAGA";
export const CREATE_PAGE_SAGA: "CREATE_PAGE_SAGA" = "CREATE_PAGE_SAGA";
export const GET_PAGE_VERSION_PREVIEW: "GET_PAGE_VERSION_PREVIEW" = "GET_PAGE_VERSION_PREVIEW";

export const SET_MODIFIER_FORM_IS_EXPAND: "SET_MODIFIER_FORM_IS_EXPAND" = "SET_MODIFIER_FORM_IS_EXPAND";
export const SET_MODIFIER_FORM_FIELDS: "SET_MODIFIER_FORM_FIELDS" = "SET_MODIFIER_FORM_FIELDS";
export const SAVE_MODIFIER_FORM_SAGA: "SAVE_MODIFIER_FORM_SAGA" = "SAVE_MODIFIER_FORM_SAGA";

export const PAGE_MODIFIERS_SET_PAGE_MODIFIERS: "PAGE_MODIFIERS_SET_PAGE_MODIFIERS" = "PAGE_MODIFIERS_SET_PAGE_MODIFIERS";
export const PAGE_MODIFIERS_RESET_PAGE_MODIFIERS: "PAGE_MODIFIERS_RESET_PAGE_MODIFIERS" = "PAGE_MODIFIERS_RESET_PAGE_MODIFIERS";
export const PAGE_MODIFIERS_ADD_PAGE_MODIFIERS: "PAGE_MODIFIERS_ADD_PAGE_MODIFIERS" = "PAGE_MODIFIERS_ADD_PAGE_MODIFIERS";
export const PAGE_MODIFIERS_REMOVE_PAGE_MODIFIERS: "PAGE_MODIFIERS_REMOVE_PAGE_MODIFIERS" = "PAGE_MODIFIERS_REMOVE_PAGE_MODIFIERS";
export const PAGE_MODIFIERS_SET_MODIFIER: "PAGE_MODIFIERS_SET_MODIFIER" = "PAGE_MODIFIERS_SET_MODIFIER";

export const GET_ACCOUNT_LIST_SAGA: "GET_ACCOUNT_LIST_SAGA" = "GET_ACCOUNT_LIST_SAGA";
export const GET_ACCOUNT_SAGA: "GET_ACCOUNT_SAGA" = "GET_ACCOUNT_SAGA";
export const SAVE_ACCOUNT_SAGA: "SAVE_ACCOUNT_SAGA" = "SAVE_ACCOUNT_SAGA";

export const ACCOUNT_GENERAL_INFO_CHANGE_NAME: "ACCOUNT_GENERAL_INFO_CHANGE_NAME" = "ACCOUNT_GENERAL_INFO_CHANGE_NAME";
export const ACCOUNT_GENERAL_INFO_CHANGE_DOMAIN: "ACCOUNT_GENERAL_INFO_CHANGE_DOMAIN" = "ACCOUNT_GENERAL_INFO_CHANGE_DOMAIN";

export const ACCOUNT_CHANGE_USERS: "ACCOUNT_CHANGE_USERS" = "ACCOUNT_CHANGE_USERS";

export const GET_REDIRECT_LIST_SAGA: "GET_REDIRECT_LIST_SAGA" = "GET_REDIRECT_LIST_SAGA";
export const DELETE_REDIRECT_LIST_SAGA: "DELETE_REDIRECT_LIST_SAGA" = "DELETE_REDIRECT_LIST_SAGA";
export const SET_REDIRECT_LIST: "SET_REDIRECT_LIST" = "SET_REDIRECT_LIST";
export const ADD_ACCOUNT_USER: "ADD_ACCOUNT_USER" = "ADD_ACCOUNT_USER";
export const REMOVE_ACCOUNT_USER: "REMOVE_ACCOUNT_USER" = "REMOVE_ACCOUNT_USER";
export const CHANGE_ACCOUNT_USER_ROLE: "CHANGE_ACCOUNT_USER_ROLE" = "CHANGE_ACCOUNT_USER_ROLE";

export const PAGE_VERSIONS_CHANGE_VERSION_HTML: "PAGE_VERSIONS_CHANGE_VERSION_HTML" = "PAGE_VERSIONS_CHANGE_VERSION_HTML";
export const PAGE_VERSIONS_CHANGE_VERSION_CSS: "PAGE_VERSIONS_CHANGE_VERSION_CSS" = "PAGE_VERSIONS_CHANGE_VERSION_CSS";
export const PAGE_VERSIONS_CHANGE_VERSION_JS: "PAGE_VERSIONS_CHANGE_VERSION_JS" = "PAGE_VERSIONS_CHANGE_VERSION_JS";
export const PAGE_VERSIONS_SET_PAGE_VERSIONS: "SET_VERSIONS" = "SET_VERSIONS";

export const SET_PAGE_RESOURCES: "SET_PAGE_RESOURCES" = "SET_PAGE_RESOURCES";
export const ADD_PAGE_RESOURCE: "ADD_PAGE_RESOURCE" = "ADD_PAGE_RESOURCE";
export const REMOVE_PAGE_RESOURCE: "REMOVE_PAGE_RESOURCE" = "REMOVE_PAGE_RESOURCE";
export const REMOVE_ALL_PAGE_RESOURCE: "REMOVE_ALL_PAGE_RESOURCE" = "REMOVE_ALL_PAGE_RESOURCE";
export const DOWNLOAD_PAGE_RESOURCES_SAGA: "DOWNLOAD_PAGE_RESOURCES_SAGA" = "DOWNLOAD_PAGE_RESOURCES_SAGA";
export const DOWNLOAD_PAGE_RESOURCES_LOADING: "DOWNLOAD_PAGE_RESOURCES_LOADING" = "DOWNLOAD_PAGE_RESOURCES_LOADING";

export const GET_REDIRECT_SAGA: "GET_REDIRECT_SAGA" = "GET_REDIRECT_SAGA";
export const SET_REDIRECT: "SET_REDIRECT" = "SET_REDIRECT";
export const CHANGE_REDIRECT_PAGE_URL: "CHANGE_REDIRECT_PAGE_URL" = "CHANGE_REDIRECT_PAGE_URL";
export const CHANGE_REDIRECT_STATUS_CODE: "CHANGE_REDIRECT_STATUS_CODE" = "CHANGE_REDIRECT_STATUS_CODE";
export const CHANGE_REDIRECT_REDIRECT_URL: "CHANGE_REDIRECT_REDIRECT_URL" = "CHANGE_REDIRECT_REDIRECT_URL";
export const SAVE_REDIRECT_SAGA: "SAVE_REDIRECT_SAGA" = "SAVE_REDIRECT_SAGA";

export const SET_LOADING: "SET_LOADING" = "SET_LOADING";

export type PAGE_TYPES_TITLE_TYPE = "Page" | "Pft" | "Template" | "Custom";
export type PAGE_TYPES_KEY_TYPE = "page" | "pft" | "template" | "custom";
export type PAGE_TYPES_OBJECT = {|
  pageTypeTitle: PAGE_TYPES_TITLE_TYPE,
  pageTypeKey: PAGE_TYPES_KEY_TYPE,
|}

export const PAGE_TYPES: Array<PAGE_TYPES_OBJECT> = [
  {
    pageTypeTitle: "Page",
    pageTypeKey: "page",
  },
  {
    pageTypeTitle: "Pft",
    pageTypeKey: "pft",
  },
  {
    pageTypeTitle: "Template",
    pageTypeKey: "template",
  },
  {
    pageTypeTitle: "Custom",
    pageTypeKey: "custom",
  },
];

export const GET_LINKING_LIST_SAGA: "GET_LINKING_LIST_SAGA" = "GET_LINKING_LIST_SAGA";
export const SET_LINKING_LIST:"SET_LINKING_LIST" = "SET_LINKING_LIST";
export const SET_LINKING_LIST_COUNT:"SET_LINKING_LIST_COUNT" = "SET_LINKING_LIST_COUNT";
export const GET_LINKING_ITEM_SAGA:"GET_LINKING_ITEM_SAGA" = "GET_LINKING_ITEM_SAGA";
export const SET_LINKING_ITEM:"SET_LINKING_ITEM" = "SET_LINKING_ITEM";
export const SET_LINKING_ITEM_NAME:"SET_LINKING_ITEM_NAME" = "SET_LINKING_ITEM_NAME";
export const SET_LINKING_ITEM_TYPE:"SET_LINKING_ITEM_TYPE" = "SET_LINKING_ITEM_TYPE";
export const SET_LINKING_ITEM_TAGS_GENERATION:"SET_LINKING_ITEM_TAGS_GENERATION" = "SET_LINKING_ITEM_TAGS_GENERATION";
export const SET_LINKING_ITEM_TAGS_DISPLAY:"SET_LINKING_ITEM_TAGS_DISPLAY" = "SET_LINKING_ITEM_TAGS_DISPLAY";
export const SET_LINKING_ITEM_TEMPLATE_CODE:"SET_LINKING_ITEM_TEMPLATE_CODE" = "SET_LINKING_ITEM_TEMPLATE_CODE";
export const SET_LINKING_ITEM_SORTING:"SET_LINKING_ITEM_SORTING" = "SET_LINKING_ITEM_SORTING";
export const SET_LINKING_ITEM_WIDTH:"SET_LINKING_ITEM_WIDTH" = "SET_LINKING_ITEM_WIDTH";
export const BULK_DELETE_LINKING_SAGA: "BULK_DELETE_LINKING_SAGA" = "BULK_DELETE_LINKING_SAGA";
export const SAVE_LINKING_ITEM_SAGA:"SAVE_LINKING_ITEM_SAGA" = "SAVE_LINKING_ITEM_SAGA";
export const SET_LINKING_ITEM_ALGORITHM:"SET_LINKING_ITEM_ALGORITHM" = "SET_LINKING_ITEM_ALGORITHM";
export const SET_LINKING_ITEM_HEIGHT:"SET_LINKING_ITEM_HEIGHT" = "SET_LINKING_ITEM_HEIGHT";
export const GET_LINKING_ITEM_HEIGHT_SAGA:"GET_LINKING_ITEM_HEIGHT_SAGA" = "GET_LINKING_ITEM_HEIGHT_SAGA";
export const SET_LINKING_ITEM_USE_PAGINATION: "SET_LINKING_ITEM_USE_PAGINATION" = "SET_LINKING_ITEM_USE_PAGINATION";

export const GET_ACCOUNT_MODULES_SAGA:"GET_ACCOUNT_MODULES_SAGA" = "GET_ACCOUNT_MODULES_SAGA";
export const SET_ACCOUNTS_MODULES:"SET_ACCOUNTS_MODULES" = "SET_ACCOUNTS_MODULES";

export const CHANGE_PAGE_TYPE_SAGA:"CHANGE_PAGE_TYPE_SAGA" = "CHANGE_PAGE_TYPE_SAGA";

export const ACCOUNT_LIST: "ACCOUNT_LIST" = "ACCOUNT_LIST";
export const PAGES_LIST: "PAGES_LIST" = "PAGES_LIST";
export const REDIRECT_LIST: "REDIRECT_LIST" = "REDIRECT_LIST";
export const LINKING_LIST: "LINKING_LIST" = "LINKING_LIST";

export const SAVE_REDIRECT_LOADING: "SAVE_REDIRECT_LOADING" = "SAVE_REDIRECT_LOADING";
export const GET_REDIRECT_LOADING: "GET_REDIRECT_LOADING" = "GET_REDIRECT_LOADING";
export const SAVE_LINKING_LOADING: "SAVE_LINKING_LOADING" = "SAVE_LINKING_LOADING";
export const GET_LINKING_LOADING: "GET_LINKING_LOADING" = "GET_LINKING_LOADING";

export const ACCOUNT_MODULES_LOADING: "ACCOUNT_MODULES_LOADING" = "ACCOUNT_MODULES_LOADING";
export const GET_PAGE_LOADING: "GET_PAGE_LOADING" = "GET_PAGE_LOADING";
export const SAVE_PAGE_LOADING: "SAVE_PAGE_LOADING" = "SAVE_PAGE_LOADING";
export const GET_PAGE_LIST_LOADING: "GET_PAGE_LIST_LOADING" = "GET_PAGE_LIST_LOADING";
export const GET_ACCOUNT_LOADING: "GET_ACCOUNT_LOADING" =  "GET_ACCOUNT_LOADING";
export const SAVE_ACCOUNT_LOADING: "SAVE_ACCOUNT_LOADING" =  "SAVE_ACCOUNT_LOADING";
export const PAGE_EXPORTING_LOADING: "PAGE_EXPORTING_LOADING" =  "PAGE_EXPORTING_LOADING";
export const PAGE_IMPORTING_LOADING: "PAGE_IMPORTING_LOADING" =  "PAGE_IMPORTING_LOADING";
export const PAGE_CLONING_LOADING: "PAGE_CLONING_LOADING" =  "PAGE_CLONING_LOADING";
export const HEIGHT_LOADING: "HEIGHT_LOADING" =  "HEIGHT_LOADING";
export const GET_PAGE_VERSION_PREVIEW_LOADING: "GET_PAGE_VERSION_PREVIEW_LOADING" =  "GET_PAGE_VERSION_PREVIEW_LOADING";
