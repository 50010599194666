/* eslint-disable indent */
// @flow
import environment from "environment";
import qs from "qs";
import get from "lodash.get";
import RequestService from "@fas/ui-framework/lib/services/request";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { userService } from "../user";
import { getHashValue } from "../jusCan";

const getAuthorizationHeaders: () => { [key: string]: mixed } = () => ({
  "X-Api-Key": userService.getApiKey() || "",
  "Account-Id": getHashValue(),
});

export const loginUrl = `${environment.endpoints.get.loginUrl}?r=${window.location.href}`;

export const requestService: RequestService = new RequestService({
  timeout: 360 * 10000, // FIXME: set timeout 60s, when api is fixed
  headers: { credentials: "include" },
  paramsSerializer: (params) => qs.stringify(params),
  customAttr: {
    getAuthorizationHeaders,
    redirect: () => {
      window.location.href = loginUrl;
    },
   },
});

export const fetchUserInfo = (): Promise<{api: string | null, isHaveAccess: boolean}> => requestService
  .get(environment.endpoints.get.apiKey, {
    customAttr: { ignoreAuthorization: true },
    withCredentials: true,
  })
  .then((body) => {
    userService.setUser(body.data.user);
    return {
      api: userService.getApiKey(),
    };
  });

export const fetchUserPermissions = (): PromiseResponse<mixed> => requestService.get(
  environment.endpoints.get.permissions
);

export const fetchUserInfoWithJUSPermissions = async () => {
  const response = await fetchUserPermissions();
  const result = get(response, "data.result", []);

  const transformedPermissions = result.reduce((acc, { accountId, permissions }) => acc.concat(permissions.map(({ permission }) => `${accountId}.${permission}`)), []);

  const user = userService.getUser();

  if (user && user.permissions && user.permissions.obj) {
    user.permissions.obj.push(...transformedPermissions);
  }

  userService.setUser(user);

  return userService.getApiKey();
};
