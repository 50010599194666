// @flow
/* eslint-disable import/max-dependencies */
import {
  SimpleTableActionButton,
  ConfirmTableActionButton,
} from "@fas/ui-core";
import {
  Visibility, FileCopy, GetApp, Check,
} from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router";

import React from "react";
import { useDispatch } from "react-redux";
import type { DataItem } from "@fas/ui-framework/lib/redux/reducers/table";
import type { PageTypes } from "../ExportPageFd/types/ExportPageFd.types";
import { getPageVerisionPreviewSaga } from "../../actions/page";
import { openClonePageDialog, openExportPageDialog } from "../../actions/pageActions";
import { activateDocumentSaga } from "../../actions/pages/actions";
import { getHashValue } from "../../services/jusCan";
import Can from "../Can";

type PropsActions = {
  item: DataItem,
};

export function PreviewAction({ item }: PropsActions) {
  const dispatch: <A>(A) => A = useDispatch();

  const handlePreview = () => {
    const { documentId, versionCollection } = item;

    const isPublished = versionCollection.some(({ version }) => version === "current");
    const currentVersionType = (isPublished) ? "current" : "new";
    const { id: versionId } = versionCollection.filter(({ version }) => version === currentVersionType)[0];

    dispatch(getPageVerisionPreviewSaga(documentId, versionId));
  };

  return (
    <SimpleTableActionButton
      tooltip="Preview"
      onClick={handlePreview}
      Icon={<Visibility sx={{ color: "text.secondary" }} />}
    />
  );
}

export function EditGeneralAction({ item }: PropsActions) {
  const hashId = getHashValue();
  const navigate = useNavigate();

  const handleEditGeneralInfo = () => {
    const { documentId } = item;
    navigate(`/pages/editInfo/${documentId}?hashId=${hashId}`);
  };

  return (
    <SimpleTableActionButton
      tooltip="EditGeneralInfo"
      onClick={handleEditGeneralInfo}
      Icon={<SettingsIcon sx={{ color: "text.secondary" }} />}
    />
  );
}

export function CloneAction({ item }: PropsActions) {
  const dispatch: <A>(A) => A = useDispatch();
  const handleClone: (string, string, PageTypes, string) => mixed = (pageId, pageName, pageType, pageUrl) => dispatch(
    openClonePageDialog(pageId, pageName, pageType, pageUrl)
  );

  return (
    <SimpleTableActionButton
      tooltip="Clone"
      onClick={() => {
        const { documentId, name, type, aliasCollection } = item;
        const last = aliasCollection[aliasCollection.length - 1];
        const url: string = last && last.alias;
        handleClone(documentId, name, type, url);
      }}
      Icon={<FileCopy sx={{ color: "text.secondary" }} />}
    />
  );
}

export function ExportAction({ item }: PropsActions) {
  const dispatch: <A>(A) => A = useDispatch();
  const handleExport = () => {
    const { documentId, name, type } = item;
    dispatch(
      openExportPageDialog(documentId, name, type)
    );
  };

  const isActive: boolean = item.versionCollection.some(
    ({ version }) => version === "current"
  );

  const isDisabled = !(["page", "pft"].includes(item.type) && isActive);

  return (
    <SimpleTableActionButton
      tooltip="Export"
      disabled={isDisabled}
      onClick={handleExport}
      Icon={<GetApp sx={{ ...(!isDisabled && {color: "text.secondary"}) }}  />}
    />
  );
}

export function ActivateAction({ item }: PropsActions) {
  const dispatch: <A>(A) => A = useDispatch();
  const handleActivate = () => {
    const { documentId } = item;
    dispatch(activateDocumentSaga(documentId));
  };
  
  const isActive: boolean = item.versionCollection.some(
    ({ version }) => version === "current"
  );

  const isHaveNew: boolean = item.versionCollection.some(
    ({ version }) => version === "new"
  );

  const isDisabled = isActive || !isHaveNew;

  return (
    <Can permissions={["documentFactory.activateVersion"]}>
      <ConfirmTableActionButton
        tooltip="Activate"
        viewPath="activate"
        items={[item]}
        disabled={isDisabled}
        onClick={() => handleActivate()}
        confirmTitle="Activate item"
        confirmText="Are you sure you want to activate"
        Icon={<Check sx={{ ...(!isDisabled && {color: "text.primary"}) }}  />}
      />
    </Can>
  );
}
