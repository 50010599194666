// @flow
/* eslint-disable import/max-dependencies */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import dayjs from "dayjs";
import type { Dispatch } from "redux";
import { useParams } from "react-router-dom";
import { Container, Box } from "@mui/material";
import { Loader } from "@fas/ui-core";
import type { State as ErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import { PAGE_TYPES } from "../../helpers/constants";
import type { PAGE_TYPES_OBJECT, PAGE_TYPES_TITLE_TYPE } from "../../helpers/constants";
import {
  getPageInfo,
  getPageList,
  getPageVersionByType,
  getPageCurrentVersionType,
  getPageVersions,
  
} from "../../selectors/page";
import {
  getErrors,
} from "../../selectors/errors";
import {
  getIsPageLoading,
  getIsPageListLoading,
} from "../../selectors/loading";
import { getDocumentAliasMode } from "../../selectors/accountModules";
import {
  changePageName,
  changePageUrl,
  changePagePftTemplate,
  changePageTags,
  changePageTypeSaga,
  setPageVersion,
} from "../../actions/pageInfo";
import {
  getPageSaga,
  getPageVerisionPreviewSaga,
} from "../../actions/page";
import {
  getPagesListSaga,
  setPagesList,
} from "../../actions/pages";
import { pageBackupStore } from "../../pages/AppStoreWrapper/store";
import { setBackup, restoreBackup } from "../../actions/backup";
import type { Actions as BackupActions, SetBackupAction, RestoreBackupAction } from "../../actions/backup";
import PageInfo from "../../components/PageInfo/PageInfo";
import PageVersionActions from "../../components/PageVersionActions";
import type { State as PageStoreState } from "../../pages/AppStoreWrapper";
import type { State as PageInfoState, PageType } from "../../reducers/pageInfo";
import type { Page } from "../../components/PagesList/types/PagesList.types";
import type {
  Actions as PageInfoActions,
  ChangePageNameAction,
  ChangePagePftTemplateAction,
  ChangePageUrlAction,
  ChangePageTags,
  ChangePageTypeSaga,
  SetPageVersionAction,
} from "../../actions/pageInfo";
import type {
  Actions as PageActions,
  GetPageSagaAction,
  GetPageVerisionPreviewSagaAction,
} from "../../actions/page";
import type {
  Actions as PageListActions,
  GetPagesListSaga,
  SetPagesList,
} from "../../actions/pages";
import type {
  DocumentAliasMode,
  Actions as AccountModuleActions,
} from "../../actions/accountModules";
import {
  resetPageModifiers,
  setPageModifiers,
  type SetPageModifiersAction,
  type ResetPageModifiersAction,
  type Actions,
} from "../../actions/pageModifiers";
import type { IncomingPageModifiersState } from "../../reducers/pageModifiers";
import type { State as PageVersionsState, Version, VersionType } from "../../reducers/pageVersions";
import { getPagesList } from "../../services/pagesApi";
import {getHashValue} from "../../services/jusCan";

type Order = "asc" | "desc";
type Filters = { [key: string]: string };
type Sorting = { [key: string]: Order };

type OwnProps = $ReadOnly<{|
  store?: Object,
|}>;

type StateToProps = $ReadOnly<{|
  isPageLoading: boolean,
  isTemplateListLoading: boolean,
  pageInfo: PageInfoState,
  templateList: Array<Page>,
  errors: ErrorsState,
  documentAliasMode: DocumentAliasMode,
  pageVersionType: VersionType,
  pageVersions: PageVersionsState,
  pageVersion: Version,
|}>;

type DispatchToProps = $ReadOnly<{|
  onChangeType: (type: PageType) => ChangePageTypeSaga,
  onChangeName: (name: string) => ChangePageNameAction,
  onChangeUrl: (url: string) => ChangePageUrlAction,
  onChangePagePftTemplate: (templateId: string, templateName: string) => ChangePagePftTemplateAction,
  onSetPageModifiers: (modifierCollection?: Array<IncomingPageModifiersState>) => SetPageModifiersAction,
  onResetPageModifiers: () => ResetPageModifiersAction,
  onGetPage: (id: string, versionId?: string | null) => GetPageSagaAction,
  onGetTemplateList: (
    page: number,
    pageSize: number,
    filter: Filters,
    order: Sorting,
  ) => GetPagesListSaga,
  onSetTemplateList: (data: Array<Page>) => SetPagesList,
  onChangeTags:(tags:Array<string>)=>ChangePageTags,
  onGetPageVersionPreview: (pageId: string, versionId: string) => GetPageVerisionPreviewSagaAction,
  onSetPageVersion: (versionType: VersionType, versionId: string) => SetPageVersionAction,
  onSetBackup: (backup: Array<string>) => SetBackupAction,
  onRestoreBackup: () => RestoreBackupAction,
|}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
|}>;

const mapStateToProps: (state: PageStoreState) => StateToProps = (state) => {
  const pageVersionType: VersionType = getPageCurrentVersionType(state);
  const pageVersion: Version = getPageVersionByType(state, pageVersionType);

  return {
    isPageLoading: getIsPageLoading(state),
    isTemplateListLoading: getIsPageListLoading(state), // FIXME: fix by optimized template api - MTU-51822
    pageInfo: getPageInfo(state),
    templateList: getPageList(state),
    errors: getErrors(state),
    documentAliasMode: getDocumentAliasMode(state),
    pageVersions: getPageVersions(state),
    pageVersionType,
    pageVersion,
  };
};

const mapDispatchToProps: (
  Dispatch<PageInfoActions
    | PageActions
    | PageListActions
    | AccountModuleActions
    | BackupActions
    | Actions>
) => DispatchToProps = (dispatch) => bindActionCreators({
  onChangeType: changePageTypeSaga,
  onChangeName: changePageName,
  onChangeUrl: changePageUrl,
  onChangePagePftTemplate: changePagePftTemplate,
  onSetPageModifiers: setPageModifiers,
  onResetPageModifiers: resetPageModifiers,
  onGetPage: getPageSaga,
  onGetTemplateList: getPagesListSaga, // FIXME: fix by optimized template api - MTU-51822
  onSetTemplateList: setPagesList,
  onChangeTags: changePageTags,
  onGetPageVersionPreview: getPageVerisionPreviewSaga,
  onSetPageVersion: setPageVersion,
  onSetBackup: setBackup,
  onRestoreBackup: restoreBackup,
},
dispatch);

function PageInfoContainer({
  isPageLoading,
  isTemplateListLoading,
  documentAliasMode,
  pageInfo,
  templateList,
  errors,
  pageVersionType,
  pageVersions,
  pageVersion,
  onChangeName,
  onChangeType,
  onChangeUrl,
  onChangePagePftTemplate,
  onGetPage,
  onGetTemplateList,
  onSetTemplateList,
  onChangeTags,
  onSetPageModifiers,
  onResetPageModifiers,
  onGetPageVersionPreview,
  onSetPageVersion,
  onSetBackup,
  onRestoreBackup,
}: Props) {
  const [inputPftTemplate, setInputPftTemplate] = useState("");
  const match = useParams();

  const { id: pageId }: { id: ?string } = match;

  const {
    name,
    pageType,
    versionId,
    tags,
    url,
    template,
  }: PageInfoState = pageInfo;
  const { templateName } = template;

  const pageTypesList: Array<PAGE_TYPES_TITLE_TYPE> = PAGE_TYPES.map((
    item: PAGE_TYPES_OBJECT
  ): PAGE_TYPES_TITLE_TYPE => item.pageTypeTitle);

  useEffect(() => {
    onSetBackup(pageBackupStore);
    if (pageId) onGetPage(pageId);
    return () => {
      onRestoreBackup();
    };
  }, [pageId, onGetPage]);

  useEffect(() => {
    if (pageType === "pft" && (inputPftTemplate === "" || inputPftTemplate === templateName)) {
      onSetTemplateList([]);
    }
    if (inputPftTemplate.length > 1 && inputPftTemplate !== templateName) {
      /* TODO: need refactor because there was using same  */
      getPagesList({
        page: 1,
        pageSize: 20,
        filters: {
          type: "template",
          name: inputPftTemplate,
        },
        sorting: {},
      }).then((response) => onSetTemplateList(response.data.data.pagesList));
    }
  }, [pageType, inputPftTemplate, templateName, onSetTemplateList, onGetTemplateList]);

  const handleInputChangePftTemplate: (value: string) => void = (value: string) => {
    setInputPftTemplate(value);
  };

  const handleChangePftTemplate: (value: string) => void = (value: string) => {
    const changedTemplate: Page | void = templateList.find((item: Page): boolean => item.name === value);
    onResetPageModifiers();
    if (changedTemplate && changedTemplate.documentId) {
      onChangePagePftTemplate(changedTemplate.documentId, value);
      // we set modifier from template without content
      onSetPageModifiers((changedTemplate.modifierCollection || []).map((item) => ({ ...item, content: "" })));
    }
  };

  const accountId: string = getHashValue();
  const versionPath = (versionId) ? `versionId=${versionId}` : "";
  const editPathName: string = (pageId) ? `/pages/edit/${pageId}?${versionPath}&hashId=${accountId}` : "";

  const handleChangePageVersion: (currentVersionType: VersionType) => void = (currentVersionType: VersionType) => {
    const { id: newVersionId }: Version = pageVersions.get(currentVersionType);
    onSetPageVersion(currentVersionType, newVersionId);
  };

  const pageVersionTypesValues: Array<{value: VersionType, title: string}> = [
    { value: "current", title: "Active version" },
    { value: "new", title: "Edit version" },
    { value: "previous", title: "Previous 1 version" },
    { value: "old", title: "Previous 2 version" },
  ].filter((item: {value: VersionType, title: string}): string => pageVersions.get(item.value).id);

  const pageVersionUpdatedAtFormatted: string = dayjs(pageVersion.updatedAt).format("DD-MM-YYYY hh:mm");

  return (
    <Container maxWidth="md">
      <Box data-testid="pages-general-info" width="100%">
        <Loader loading={isPageLoading}>
          <PageInfo
            documentAliasMode={documentAliasMode}
            pageTypesList={pageTypesList}
            pageType={pageType}
            pageId={pageId}
            name={name}
            url={url}
            template={template}
            templateList={templateList.map((item: Page): string => item.name)}
            isTemplateListLoading={isTemplateListLoading}
            tags={tags}
            // $FlowFixMe
            errors={errors.toJS()}
            onChangeType={onChangeType}
            onChangeName={onChangeName}
            onChangeUrl={onChangeUrl}
            onChangePftTemplate={handleChangePftTemplate}
            onInputChangePftTemplate={handleInputChangePftTemplate}
            onChangeTags={onChangeTags}
          />
          {pageId && (
            <PageVersionActions
              pageVersionType={pageVersionType}
              pageVersionUpdatedAt={pageVersionUpdatedAtFormatted}
              pageVersionTypesValues={pageVersionTypesValues}
              onSetPageVersion={handleChangePageVersion}
              onGetPageVersionPreview={
                (): GetPageVerisionPreviewSagaAction => onGetPageVersionPreview(pageId, versionId)
              }
              editPathName={editPathName}
            />
          )}
        </Loader>
      </Box>
    </Container>
  );
}

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(PageInfoContainer);
