// @flow
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Container, Grid } from "@mui/material";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { FormHeader } from "@fas/ui-core";
import { getIsAccountLoading, getIsSaveAccountLoading } from "../../selectors/loading";
import { getAccount, saveAccount } from "../../actions/editAccount";
import AccountInfo from "../AccountInfo";
import AccountUsers from "../AccountUsers";
import { restoreBackup, setBackup } from "../../actions/backup";
// eslint-disable-next-line import/max-dependencies
import { accountBackupStore } from "../../pages/AppStoreWrapper/store";

function EditAccountForm() {
  const params = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const accountId = params && params.id;
  const dispatch = useDispatch();
  const isAccountLoading: boolean = useSelector((state) => getIsAccountLoading(state));
  const isAccountSaveLoading: boolean = useSelector((state) => getIsSaveAccountLoading(state));

  useEffect(() => {
    if(accountId) {
      dispatch(getAccount(accountId));
    }
  }, [accountId]);
  const handleSave = () => {
    dispatch(saveAccount(accountId, navigate));
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if(accountId) {
      dispatch(setBackup(accountBackupStore));
      return () => dispatch(restoreBackup());
    }
  }, [pathname]);

  return (
    <Box width={1}>
      <FormHeader
        loading={isAccountSaveLoading || isAccountLoading}
        title={accountId ? "Edit account" : "Create account"}
        onClick={handleSave}
      />
      {isAccountLoading && (
        <Grid container justifyContent="center">
          <Box mt={40}>
            <CircularProgress />
          </Box>
        </Grid>
      )}
      {!isAccountLoading && (
        <Container maxWidth="md">
          <AccountInfo/>
          <AccountUsers/>
        </Container>
      )}
    </Box>
  );
}

export default EditAccountForm;
