// @flow
/* eslint-disable import/max-dependencies */
import {
  call, put, select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { SAVE_ACCOUNT_SAGA, SAVE_ACCOUNT_LOADING } from "../../helpers/constants";
import setLoading from "../../actions/loading";
import { createAccount, updateAccount } from "../../services/accountApi/account";
import type { SaveAccountSaga } from "../../actions/editAccount/actions";
import type { AccountLoadingTypes as LoadingTypes } from "../../actions/loading";
import {getGeneralInfo, getUsers} from "../../selectors/account";
import errorsTransform from "../../helpers/errorsTransform";
import {validateSchema} from "./schema";


export function* makeFetch(action: SaveAccountSaga): Saga<void> {
  try {
    yield put(setLoading<LoadingTypes>(SAVE_ACCOUNT_LOADING, true));
    const { accountId, navigate }: SaveAccountSaga = action;
    const {name, domain, description, isActive} = yield select(getGeneralInfo);
    const users = yield select(getUsers);
    const newData = {
      name,
      domain,
      description,
      isActive,
      users,
    };
    const validationErrors = errorsTransform(
      validateSchema.validate(newData)
    );
    const isError: boolean = Object.keys(validationErrors).length !== 0;

    if(isError) {
      yield put(setErrors(validationErrors));
    }
    else if (accountId) {
      yield call(updateAccount, accountId, newData);
    }
    else {
      yield call(createAccount, newData);
      navigate("/");
    }

  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to save an account", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading<LoadingTypes>(SAVE_ACCOUNT_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(SAVE_ACCOUNT_SAGA, makeFetch);
}
