// @flow
import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  TextField,
} from "@mui/material";
import debounce from "lodash.debounce";
import { ComboBox } from "@fas/ui-core";
import type { Page } from "../FormDialog/types/FormDialog.types";
import FormDialog from "../FormDialog";
import { getAccountList } from "../../services/accountApi/account";

interface Props {
  open: boolean,
  loading: boolean,
  page: Page,
  mainAccountId: string,
  onApply: (pageId: string, pageName: string, selectedAccount: string, pageUrl: string) => void,
  onClose: () => void,
}

function ClonePageFd({
  open,
  loading,
  page: {
    id: pageId,
    name: pageName,
    type: pageType,
    url: pageUrl,
  },
  mainAccountId,
  onApply,
  onClose,
}: Props) {
  const currentAccount = { id: mainAccountId, name: "Clone to current account" };
  const [selectedAccount, setSelectedAccount] = useState(currentAccount);
  const [newPageName, setNewPageName] = useState("");
  const [newPageUrl, setNewPageUrl] = useState("");
  const [accountSearch, setAccountSearch] = useState("");
  const [accountsList, setAccountsList] = useState([currentAccount]);

  const resetDialog = () => {
    setSelectedAccount(currentAccount);
    setNewPageName("");
  };
  const handleClose = () => {
    onClose();
    resetDialog();
  };
  const handleApply = () => {
    onApply(pageId, newPageName, selectedAccount.id, newPageUrl);
  };

  const handleGetAccountList = (params) => {
    getAccountList(params)
      .then((response) => setAccountsList([currentAccount, ...response.data.data.accountsList]));
  };

  const debouncedFetch = useCallback(debounce(handleGetAccountList, 1000), []);

  useEffect(() => {
    /* TODO: need refactor because there was using same  */
    if(accountSearch.length) {
      debouncedFetch({
        page: 1,
        pageSize: 20,
        filters: { name: accountSearch },
        sorting: {},
      });
    }
  }, [accountSearch]);
  useEffect(() => {
    setNewPageName(`${pageName} (clone)`);
  }, [pageName]);
  useEffect(() => {
    setNewPageUrl(pageUrl);
  }, [pageUrl]);

  return (
    <FormDialog
      open={open}
      loading={loading}
      title={`Clone: ${pageName}`}
      onApply={handleApply}
      onClose={handleClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            id="pageName"
            label="Page Name"
            type="text"
            value={newPageName}
            fullWidth
            variant="outlined"
            onChange={(e) => setNewPageName(e.target.value)}
            data-testid="clone-page-new-name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            id="url"
            label="Url"
            type="text"
            value={newPageUrl}
            fullWidth
            variant="outlined"
            onChange={(e) => setNewPageUrl(e.target.value)}
            data-testid="clone-page-new-url"
          />
        </Grid>
        <Grid item xs={12}>
          <ComboBox
            id="clone-page-list-accounts"
            label="Account"
            options={accountsList.filter((item) => item.id !== selectedAccount)}
            value={selectedAccount}
            defaultValue={selectedAccount}
            getOptionLabel={(val) => val.name}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            onChange={(e, value) => setSelectedAccount(value)}
            disableClearable
            data-testid="clone-page-list-accounts"
            disabled={Boolean(pageType !== "template")}
            onInputChange={(e, value) => setAccountSearch(value)}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
}

ClonePageFd.defaultProps = {
  open: false,
  loading: false,
  accountsList: [],
  page: {
    id: "",
    name: "",
    type: "",
    url: "",
  },
  mainAccountId: "",
};

export default ClonePageFd;
