// @flow
import type {User} from "../../pages/EditAccount";
import {
  ACCOUNT_CHANGE_USERS,
  ADD_ACCOUNT_USER,
  REMOVE_ACCOUNT_USER,
  CHANGE_ACCOUNT_USER_ROLE,
} from "../../helpers/constants";

export type ChangeUsersAction = {|
  type: typeof ACCOUNT_CHANGE_USERS,
  value: Array<User>,
|};

export type AddAccountUserAction = {|
  type: typeof ADD_ACCOUNT_USER,
  value: User,
|};

export type RemoveAccountUserAction = {|
  type: typeof REMOVE_ACCOUNT_USER,
  userId: string,
|};

export type ChangeAccountUserRoleAction = {|
  type: typeof CHANGE_ACCOUNT_USER_ROLE,
  userId: string,
  value: string,
|};

export type Actions =
  | ChangeUsersAction
  | AddAccountUserAction
  | RemoveAccountUserAction
  | ChangeAccountUserRoleAction;

export const changeAccountUsers = (
  value: Array<User>
): ChangeUsersAction => ({
  type: ACCOUNT_CHANGE_USERS,
  value,
});

export const addAccountUser = (
  value: User
): AddAccountUserAction => ({
  type: ADD_ACCOUNT_USER,
  value,
});

export const removeAccountUser = (
  userId: string
): RemoveAccountUserAction => ({
  type: REMOVE_ACCOUNT_USER,
  userId,
});

export const changeAccountUserRole = (
  userId: string,
  value: string
): ChangeAccountUserRoleAction => ({
  type: CHANGE_ACCOUNT_USER_ROLE,
  userId,
  value,
});
