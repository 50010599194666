// @flow
import {
  GET_ACCOUNT_LIST_SAGA,
} from "../../helpers/constants";

export type GetAccountListSaga = {|
  filters: {
    name?: string,
    domain?: string,
    user?: string,
  },
  type: typeof GET_ACCOUNT_LIST_SAGA
|}

export type Actions = GetAccountListSaga;

export const getAccountList = (filters: {
   name?: string,
   domain?: string,
   user?: string,
 }): GetAccountListSaga => ({
  filters,
  type: GET_ACCOUNT_LIST_SAGA,
});
