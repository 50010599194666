// @flow
import React, {useState} from "react";
import {
  IconButton, Card, CardActionArea, CardActions, Typography, CardContent, Popover, Fade, MenuItem, Menu,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

type Props = {
  info: {
    name: string,
    domain: string,
    users: string[],
    id: string,
  },
  handleClickArea: (string) => void,
  handleEditClick: (string) => void
}
function AccountCard({info, handleClickArea, handleEditClick}: Props) {
  const [anchorElUsers, setAnchorElUsers] = useState(null);
  const [anchorElActions, setAnchorElActions] = useState(null);

  const handlePopoverOpenUsers = (event) => {
    setAnchorElUsers(event.currentTarget);
  };
  const handlePopoverOpenActions = (event) => {
    setAnchorElActions(event.currentTarget);
  };

  const handlePopoverCloseUsers = () => {
    setAnchorElUsers(null);
  };
  const handlePopoverCloseActions = () => {
    setAnchorElActions(null);
  };

  const openUsers = Boolean(anchorElUsers);
  const openActions = Boolean(anchorElActions);

  return (
    <Fade in timeout={1000}>
      <Card variant="outlined" sx={{
        width:"220px",
        height: "180px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      data-testid={info.name}
      >
        <CardActionArea onClick={() => handleClickArea(info.id)} sx={{height: "100%"}}>
          <CardContent>
            <Typography variant="h6" sx={{lineHeight: "1.5"}} mb={1}>
              {info.name}
            </Typography>
            <Typography variant="subtitle2" sx={{wordWrap: "break-word"}}>
              {info.domain}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions sx={{justifyContent: "space-between"}}>
          <Typography
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpenUsers}
            onMouseLeave={handlePopoverCloseUsers}
            sx={{textDecoration: "underline", cursor: "pointer"}}
          >
            User list
          </Typography>
          <Popover
            id="users-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={openUsers}
            anchorEl={anchorElUsers}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverCloseUsers}
            disableRestoreFocus
          >
            <CardContent p={0}>
              <Typography variant="body2" component="div">
                Users: {info.users.join(",")}
              </Typography>
            </CardContent>
          </Popover>
          <IconButton
            aria-label="settings"
            onClick={handlePopoverOpenActions}
            data-testid="actions-button"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            open={openActions}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            anchorEl={anchorElActions}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handlePopoverCloseActions}
            data-testid="actions-menu"
          >
            <MenuItem onClick={() => handleEditClick(info.id)}>
              Edit account
            </MenuItem>
          </Menu>
        </CardActions>
      </Card>
    </Fade>
  );
}

export default AccountCard;
