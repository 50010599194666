// @flow
import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  FormHelperText,
  Link,
  List,
  ListItem,
} from "@mui/material";
import {AutocompleteWithFetch, SetupCard} from "@fas/ui-core";
import {getErrorsMessage} from "@fas/ui-framework/lib/redux/selectors/errors/selectors";
import {removeError} from "@fas/ui-framework/lib/redux/actions/errors/actions";
import {getUsers} from "../../selectors/account";
import UserComponent from "./User";
import {addAccountUser, changeAccountUserRole, removeAccountUser} from "../../actions/accountUsers";
import {getUsersList} from "../../services/usersApi/users";

function AccountUsers() {
  const dispatch = useDispatch();
  const users = useSelector((state) => getUsers(state));
  const errors = useSelector((state) => getErrorsMessage(state));

  const handleRemoveUser = (id: string) => {
    dispatch(removeAccountUser(id));
  };
  const handleChangeRole = (id: string, role: string, idx: number) => {
    dispatch(changeAccountUserRole(id, role));
    dispatch(removeError([idx.toString()]));
  };
  const handleAddUser = (val) => {
    // $FlowFixMe
    const isUserDublicate = users.find((i) => i.id === val.value);
    if(isUserDublicate) {
      setShowUsersList(false);
      return;
    }
    dispatch(addAccountUser({
      // $FlowFixMe
      id: val.value,
      // $FlowFixMe
      username: val.label,
    }));
    setShowUsersList(false);
  };

  const [isShowUsersList, setShowUsersList] = useState(false);

  const handleShowUsersList = () => {
    setShowUsersList(true);
  };

  return (
    <SetupCard title="Users">
      <Box width="100%">
        <List dense  data-testid="users-list">
          {
            users.map((user, idx) => (
              <UserComponent
                isError={Boolean(errors[idx.toString()])}
                key={user.id}
                user={user}
                onChangeRole={(id, role) => handleChangeRole(id, role, idx)}
                onRemoveUser={handleRemoveUser}
              />)
            )
          }
          {
            !isShowUsersList && (
              <ListItem key="addUser" button sx={{ marginTop: "10px"}}>
                <Link onClick={handleShowUsersList} data-testid="add-user">+ Add User</Link>
              </ListItem>
            )
          }
        </List>
        {isShowUsersList && (
          <AutocompleteWithFetch
            data-testid="add-user-input"
            disableClearable
            fetchMethod={getUsersList}
            onChange={handleAddUser}/>
        )}
        {errors.users && (
          <FormHelperText data-testid="users-list-error" error>
            {errors.users}
          </FormHelperText>
        )}
      </Box>
    </SetupCard>
  );
}

export default AccountUsers;
