/* eslint-disable import/max-dependencies */
/* eslint-disable react/require-default-props */
// @flow
import React, { useState, type Node } from "react";
import {
  Box, Grow, IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Fullscreen from "@mui/icons-material/Fullscreen";
import Close from "@mui/icons-material/Close";
import AceEditor from "react-ace";
import type { AceEditor as AceEditorProps } from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-beautify";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-options";
import "ace-builds/src-noconflict/snippets/html";
import "ace-builds/src-noconflict/snippets/javascript";
import "ace-builds/src-noconflict/snippets/css";
import "ace-builds/src-noconflict/ext-emmet";
import type { Ace } from "ace-builds";

type Props = {
  name: string,
  value: string,
  height: string,
  placeholder?: string,
  onBlur?: (e: any) => void,
  onChange: (value: string) => void,
  language: "javascript" | "html" | "css",
  EditorActions?: () => Node,
};

const useStyles = makeStyles(() => ({
  fullscreenEditor: {
    position: "fixed",
    margin: "auto",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    boxShadow: "0px 0px 0px 20rem rgb(0 0 0 / 60%)",
    zIndex: 1300,
    width: "96vw",
    height: "96vh",
  },
  fullscreenBtn: {
    position: "absolute",
    zIndex: 5,
    color: "white",
    right: "15px",
  },
  fullscreenActions: {
    position: "absolute",
    zIndex: 10,
    color: "white",
    right: "15px",
    top: "1px",
    display: "flex",
    flexDirection: "column",
    background: "rgba(0, 0, 0, 0.15)",
  },
}));

const modifiersWordCompleter = {
  getCompletions(editor, session, pos, prefix, callback) {
    let wordList = [];
    if (session.$modeId === "ace/mode/html") {
      wordList = [
        "{{title}}",
        "{{description}}",
        "{{meta_title}}",
        "{{meta_keywords}}",
        "{{anchor}}",
        "{{first_content}}",
        "{{second_content}}",
        "{{third_content}}",
        "{{noindex}}",
        "{{alias}}",
        "{{page_url}}",
        "{{full_domain}}",
        "{{content_domain_1}}",
        "{{content_domain_2}}",
        "{{resource: }}",
      ];
    }

    callback(
      null,
      wordList.map((word) => ({
        caption: word,
        value: word,
        meta: "html",
      }))
    );
  },
};

function CodeEditor({
  name,
  placeholder,
  height,
  onChange,
  onBlur,
  language,
  value,
  EditorActions = () => null,
}: Props) {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const classes = useStyles();

  const handleClickAway: () => void = () => {
    setIsFullscreen(false);
  };

  function onLoad(ace) {
    ace.completers.push(modifiersWordCompleter);
  }



  const sharedProps: AceEditorProps = {
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    enableSnippets: true,
    setOptions: {
      enableEmmet: true,
      useWorker: true,
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
    },
    theme: "monokai",
    width: "100%",
    onLoad,
    commands: [
      {
        name: "closeFile",
        bindKey: {win: "Esc", mac: "Esc"},
        exec: handleClickAway,
      },
    ],
  };

  function handleBlur(e: any, editor: Ace.Editor) {
    if (onBlur && editor) {
      onBlur(editor.getValue());
    }
  }

  return (
    <Box width="100%" position="relative" data-testid={`code-editor-${name}`}>
      <IconButton
        className={classes.fullscreenBtn}
        onClick={() => setIsFullscreen(!isFullscreen)}
        data-testid="fullscreen-btn"
      >
        <Fullscreen />
      </IconButton>
      <AceEditor
        placeholder={placeholder}
        name={name}
        mode={language}
        onChange={onChange}
        onBlur={onBlur && handleBlur}
        value={value}
        height={height}
        {...sharedProps}
      />
      {isFullscreen && (
        <Grow in={isFullscreen}>
          <Box className={classes.fullscreenEditor}>
            <AceEditor
              placeholder={placeholder}
              name={`${name}-fullscreen`}
              mode={language}
              onChange={onChange}
              onBlur={onBlur && handleBlur}
              value={value}
              height="100%"
              {...sharedProps}
            />
            <Box className={classes.fullscreenActions}>
              <IconButton
                onClick={handleClickAway}
                data-testid="exit-fullscreen-btn"
                color="inherit"
              >
                <Close />
              </IconButton>
              <EditorActions />
            </Box>
          </Box>
        </Grow>
      )}
    </Box>
  );
};

export default CodeEditor;
