// @flow
/* eslint-disable import/max-dependencies */
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";

import type { State } from "../../pages/AppStoreWrapper";
import type { Page } from "../../components/FormDialog/types/FormDialog.types";
import type {State as ImportState} from "../../reducers/importPage";
import type {State as CloneState} from "../../reducers/clonePage";
import type {State as ExportState } from "../../reducers/exportPage";
import type {Page as ExportPage } from "../../reducers/exportPage/reducer";

export const getIsPageImportingOpen: OutputSelector<State, *, boolean> = createSelector(
  (state: State): ImportState => state.page.importPage,
  (importing: ImportState): boolean => importing.get("isOpen", false)
);

export const getIsPageCloningOpen: OutputSelector<State, *, boolean> = createSelector(
  (state: State): CloneState => state.page.clonePage,
  (importing: CloneState): boolean => importing.get("isOpen", false)
);

export const getCloningSelectedPage: OutputSelector<State, *, Page> = createSelector(
  (state: State): CloneState => state.page.clonePage,
  (importing: CloneState): Page => importing.get("selectedPage")
);

export const getIsPageExportingOpen: OutputSelector<State, *, boolean> = createSelector(
  (state: State): ExportState => state.page.exportPage,
  (importing: ExportState): boolean => importing.get("isOpen", false)
);

export const getExportingSelectedPage: OutputSelector<State, *, ExportPage> = createSelector(
  (state: State): ExportState => state.page.exportPage,
  (exporting: ExportState): ExportPage => exporting.get("selectedPage")
);
