/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import DocumentTitle from "react-document-title";
import { Grid, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PagesContainer from "../../containers/PagesContainer";
import ClonePageDialog from "../../containers/ClonePageDialog";
import ExportPageDialog from "../../containers/ExportPageDialog";
import ImportPageDialog from "../../containers/ImportPageDialog";

const useStyles = makeStyles((theme) => ({
  mainBackground: {
    background: theme.palette.background.mainBg,
    minHeight: "calc(100% - 64px)",
  },
}));

function Pages() {
  const classes = useStyles();

  return (
    <DocumentTitle title="Page List">
      <Grid container spacing={0} className={classes.mainBackground}>
        <Container maxWidth={false}>
          <PagesContainer />
          <ClonePageDialog />
          <ExportPageDialog />
          <ImportPageDialog />
        </Container>
      </Grid>
    </DocumentTitle>
  );
};

export default Pages;
