// @flow
import {
  SET_PAGE_RESOURCES,
  ADD_PAGE_RESOURCE,
  REMOVE_PAGE_RESOURCE,
  REMOVE_ALL_PAGE_RESOURCE,
  DOWNLOAD_PAGE_RESOURCES_SAGA,
} from "../../helpers/constants";
import type { Resource, IncominResource } from "../../reducers/pageResources";
import type { DownloadResource } from "../../components/PageSettingsResources/types/PageSettingsResources.types";

export type ResourceFileFormats =
  | "image/png"
  | "image/jpeg"
  | "image/x-icon"
  | "image/svg+xml"
  | "video/mp4"
  | "font/woff2"
  | "image/vnd.microsoft.icon"
  | "image/gif";

export type SetPageResources = {|
  type: typeof SET_PAGE_RESOURCES,
  payload: Array<IncominResource>,
|}

export type AddPageResourceAction = {|
  type: typeof ADD_PAGE_RESOURCE,
  content: Resource,
|}

export type RemovePageResourceAction = {|
  type: typeof REMOVE_PAGE_RESOURCE,
  index: number,
|}

export type RemoveAllPageResourceAction = {|
  type: typeof REMOVE_ALL_PAGE_RESOURCE,
|}

export type DownloadPageResourcesSaga = {|
  type: typeof DOWNLOAD_PAGE_RESOURCES_SAGA,
  payload: DownloadResource[],
|}

export type Actions =
  SetPageResources
  | DownloadPageResourcesSaga
  | AddPageResourceAction
  | RemoveAllPageResourceAction
  | RemovePageResourceAction;

export const setPageResources = (payload: Array<IncominResource>): SetPageResources => ({
  type: SET_PAGE_RESOURCES,
  payload,
});

export const addPageResource = (content: Resource): AddPageResourceAction => ({
  type: ADD_PAGE_RESOURCE,
  content,
});

export const removePageResource = (index: number): RemovePageResourceAction => ({
  type: REMOVE_PAGE_RESOURCE,
  index,
});

export const removeAllPageResource = (): RemoveAllPageResourceAction => ({
  type: REMOVE_ALL_PAGE_RESOURCE,
});

export const downloadPageResourcesSaga = (payload: DownloadResource[]): DownloadPageResourcesSaga => ({
  type: DOWNLOAD_PAGE_RESOURCES_SAGA,
  payload,
});