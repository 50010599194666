// @flow
/* eslint-disable import/max-dependencies */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Dispatch } from "redux";
import { useParams, useNavigate, type NavigateFunction } from "react-router-dom";
import {
  Container,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@fas/ui-core";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import type { Actions as ErrorsActions, SetErrorsAction } from "@fas/ui-framework/lib/redux/actions/errors";
import type { State as ErrorsState, ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import { getRedirectId, getRedirectInfo } from "../../selectors/redirect";
import {
  getIsRedirectLoading,
  getIsSaveRedirectLoading,
} from "../../selectors/loading";
import { getErrors } from "../../selectors/errors";
import RedirectlInfo from "../../components/RedirectInfo";
import {
  getRedirectSaga,
  saveRedirect,
} from "../../actions/redirect";
import {
  changeRedirectPageUrl,
  changeRedirectStatusCode,
  changeRedirectRedirectUrl,
} from "../../actions/redirectInfo";

import { redirectBackupStore } from "../../pages/AppStoreWrapper/store";
import { setBackup, restoreBackup } from "../../actions/backup";
import type { Actions as BackupActions, SetBackupAction, RestoreBackupAction } from "../../actions/backup";

import type { State as RedirectStoreState } from "../../pages/AppStoreWrapper";
import type { State as RedirectInfoState } from "../../reducers/redirectInfo";
import type { 
  Actions as RedirectActions,
  GetRedirectSagaAction,
  SaveRedirectSagaAction,
} from "../../actions/redirect";
import type {
  Actions as RedirectInfoActions,
  ChangeRedirectPageUrlAction,
  ChangeRedirectRedirectUrlAction,
  ChangeRedirectStatusCodeAction,
} from "../../actions/redirectInfo";
import type { Redirect } from "./types/RedirectInfo.type";

type OwnProps = $ReadOnly<{|
  store?: Object,
|}>;

type StateToProps = $ReadOnly<{|
  isRedirectLoading: boolean,
  isSaveRedirectLoading: boolean,
  redirectId: string,
  redirectInfo: RedirectInfoState,
  errors: ErrorsState,
|}>

type DispatchToProps = $ReadOnly<{|
  onChangePageUrl: (pageUrl: string) => ChangeRedirectPageUrlAction,
  onChangeStatusCode: (statusCode: string) => ChangeRedirectStatusCodeAction,
  onChangeRedirectUrl: (redirectUrl: string) => ChangeRedirectRedirectUrlAction,
  onGetRedirect: (id: string) => GetRedirectSagaAction,
  onSetErrors: (mapper: ErrorsMapper) => SetErrorsAction,
  onSaveRedirect: (redirectId?: string, redirect: Redirect, navigate: NavigateFunction) => SaveRedirectSagaAction,
  onSetBackup: (backup: Array<string>) => SetBackupAction,
  onRestoreBackup: () => RestoreBackupAction,
|}>

type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
|}>;

const mapStateToProps: (RedirectStoreState) => StateToProps = (state) => ({
  isRedirectLoading: getIsRedirectLoading(state),
  isSaveRedirectLoading: getIsSaveRedirectLoading(state),
  redirectId: getRedirectId(state),
  redirectInfo: getRedirectInfo(state),
  errors: getErrors(state),
});

const mapDispatchToProps: (
  Dispatch<RedirectActions | RedirectInfoActions | ErrorsActions  | BackupActions>
) => DispatchToProps = (dispatch) => bindActionCreators({
  onChangePageUrl: changeRedirectPageUrl,
  onChangeStatusCode: changeRedirectStatusCode,
  onChangeRedirectUrl: changeRedirectRedirectUrl,
  onGetRedirect: getRedirectSaga,
  onSetErrors: setErrors,
  onSaveRedirect: saveRedirect,
  onSetBackup: setBackup,
  onRestoreBackup: restoreBackup,
}, dispatch);

function RedirectInfo({
  isRedirectLoading,
  isSaveRedirectLoading,
  onSaveRedirect,
  redirectInfo,
  errors,
  onChangePageUrl,
  onChangeStatusCode,
  onChangeRedirectUrl,
  onGetRedirect,
  onSetErrors,
  onSetBackup,
  onRestoreBackup,
}: Props) {
  const {
    pageUrl,
    statusCode,
    redirectUrl,
  }: RedirectInfoState = redirectInfo;
  const statusCodesList: string[] = ["301", "302"];
  const { id: redirectId } = useParams();
  const disableSave: boolean = isRedirectLoading || !(!errors.size);

  const navigate = useNavigate();

  const redirect: Redirect = {
    pageUrl,
    statusCode: Number(statusCode),
    redirectUrl,
  };

  useEffect(() => {
    onSetBackup(redirectBackupStore);
    if (redirectId) onGetRedirect(redirectId);
    return () => {
      onRestoreBackup();
    };
  }, [redirectId, onGetRedirect]);

  const handleChangePageUrl: (value: string) => void = (value: string) => {
    errors.has("pageUrl") && onSetErrors({});
    onChangePageUrl(value);
  };
  const handleChangeStatusCode: (value: string) => void = (value: string) => {
    errors.has("statusCode") && onSetErrors({});
    onChangeStatusCode(value);
  };
  const handleChangeRedirectUrl: (value: string) => void = (value: string) => {
    errors.has("redirectUrl") && onSetErrors({});
    onChangeRedirectUrl(value);
  };

  return (
    <Container maxWidth="md">
      <Box data-testid="pages-general-info">
        {isRedirectLoading ? (
          <Grid container justifyContent="center">
            <Box mt={40}>
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <RedirectlInfo
            pageUrl={pageUrl}
            statusCode={statusCode}
            statusCodesList={statusCodesList}
            redirectUrl={redirectUrl}
            // $FlowFixMe
            errors={errors.toJS()}
            onChangePageUrl={handleChangePageUrl}
            onChangeStatusCode={handleChangeStatusCode}
            onChangeRedirectUrl={handleChangeRedirectUrl}
          />
        )}
      </Box>
      <Box mt={3} display="flex" justifyContent="flex-end">
        <LoadingButton
          type="button"
          size="medium"
          variant="contained"
          color="primary"
          onClick={() => onSaveRedirect(redirectId, redirect, navigate)}
          data-testid="next-button"
          loading={isSaveRedirectLoading}
          disabled={disableSave}
        >
          Save
        </LoadingButton>
      </Box>
    </Container>
  );
}

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(RedirectInfo);
