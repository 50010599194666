// @flow
import {
  takeEvery, call, put, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { DOWNLOAD_PAGE_RESOURCES_SAGA, DOWNLOAD_PAGE_RESOURCES_LOADING } from "../../helpers/constants";
import type {
  DownloadPageResourcesSaga,
} from "../../actions/pageResources";
import { getHashValue } from "../../services/jusCan";
import documentApi from "../../services/documentApi";
import setLoading from "../../actions/loading";
import { getPageVersionId } from "../../selectors/page";
import { downloadSingleResource, downloadAllResources } from "../../helpers/downloadResources";

const accountId = getHashValue();

export function* makeFetch( { payload: downloadData }: DownloadPageResourcesSaga): Saga<void> {
  try {
    yield put(setLoading(DOWNLOAD_PAGE_RESOURCES_LOADING, true));

    const versionId = yield select(getPageVersionId);

    const { data } = yield call(documentApi.getJusResourcesContent, accountId, versionId, downloadData);
    const resources = data.data;

    if (resources.length === 1) {
      downloadSingleResource(resources[0]);
    }
    else{
      downloadAllResources(resources);
    }
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch download resources", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(DOWNLOAD_PAGE_RESOURCES_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(DOWNLOAD_PAGE_RESOURCES_SAGA, (makeFetch: Function));
}
