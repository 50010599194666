// @flow
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import {
  SimpleTableActionButton,
  ConfirmTableActionButton,
  TableActions,
  TableActionGroup,
} from "@fas/ui-core";
import { Edit, Delete } from "@mui/icons-material";
import externalRedirect from "../../helpers/ExternalRedirect";
import { LINKING_LIST } from "../../helpers/constants";
import Can from "../Can";
import {
  bulkDeleteLinkingInfoSaga,
} from "../../actions/linkingList";
import {getHashValue} from "../../services/jusCan";

export type ActionProps = {|
|};

type OwnProps = {|
|};

type Props = { ...OwnProps, ...ActionProps };

const handleEdit = (id) => {
  externalRedirect({ to: `/linking/edit/${id}?hashId=${getHashValue()}`, target: "_blank" });
};

function ActionsMenu({}: Props) {
  const items = useSelector(
    (state) => getTableSelections(state, LINKING_LIST)
  );
  const dispatch: <A>(A) => A = useDispatch();
  const handleDelete = () => {
    dispatch(bulkDeleteLinkingInfoSaga());
  };

  return (
    <TableActions>
      <TableActionGroup>
        <Can permissions={["linking.update"]}>
          <SimpleTableActionButton
            tooltip="Edit"
            disabled={items.length > 1}
            onClick={() => handleEdit(items[0].id)}
            Icon={<Edit />}
          />
        </Can>
        <Can permissions={["linking.remove"]}>
          <ConfirmTableActionButton
            tooltip="Delete"
            viewPath="delete" // ToDO fix this with normal prop and key id https://jira.togethernetworks.com/browse/MTU-71122
            items={items}
            onClick={() => handleDelete()}
            Icon={<Delete />}
            confirmTitle="Delete items "
            confirmText="Are you sure you want to delete "
          />
        </Can>
      </TableActionGroup>
    </TableActions>
  );
}

export default ActionsMenu;
