// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Dispatch } from "redux";
import { Box, Button } from "@mui/material";
import PagesList from "../../components/PagesList";
import { openImportPageDialog } from "../../actions/pageActions/actions";
import {
  getPagesListLoading,
} from "../../selectors/page";
import type {State} from "../../pages/AppStoreWrapper";


type OwnProps = $ReadOnly<{| store?: {} |}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  openImportPage: () => void,
|}>;

const mapStateToProps = (state: State) => ({
  loading: getPagesListLoading(state),
});

const mapDispatchToProps: Dispatch<*> = (dispatch) => bindActionCreators(
  {
    openImportPage: openImportPageDialog,
  },
  dispatch
);

function PagesContainer({
  openImportPage,
}: Props) {
  return (
    <Box mt={3} mb={3}>
      <Box display="flex" justifyContent="flex-end" mb={1}>
        <Button variant="outlined" onClick={openImportPage} data-testid="import-csv-button">Import CSV</Button>
      </Box>
      <PagesList />
    </Box>
  );
}

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(PagesContainer);
