// @flow
import React from "react";
import { connect } from "react-redux";
import ClonePageFd from "../../components/ClonePageFd";
import { closeClonePageDialog, startClonePage } from "../../actions/pageActions";
import type { Page } from "../../components/FormDialog/types/FormDialog.types";
import {getHashValue} from "../../services/jusCan";
import { getIsPageCloningOpen, getCloningSelectedPage } from "../../selectors/pageActions";
import { getIsClonePageLoading } from "../../selectors/loading";

type OwnProps = $ReadOnly<{|
  store?: Object,
|}>;

type Props = $ReadOnly<{|
...OwnProps,
  isOpen: boolean,
  selectedPage: Page,
  startClone: (pageId: string, pageName: string, accountId: string, pageUrl: string) => void,
  closeDialog: () => void,
  loading: boolean,
|}>;

const mapStateToProps = (state) => ({
  isOpen: getIsPageCloningOpen(state),
  selectedPage: getCloningSelectedPage(state),
  loading: getIsClonePageLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  startClone: (pageId: string, pageName: string, accountId: string, pageUrl: string) => dispatch(
    startClonePage(pageId, pageName, accountId, pageUrl)
  ),
  closeDialog: () => dispatch(closeClonePageDialog()),
});

function ClonePageDialog({
  isOpen,
  selectedPage,
  startClone,
  closeDialog,
  loading,
}: Props) {
  const mainAccountId = getHashValue();

  return (
    <ClonePageFd
      open={isOpen}
      page={selectedPage}
      mainAccountId={mainAccountId}
      onApply={startClone}
      onClose={closeDialog}
      loading={loading}
    />
  );
}

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(ClonePageDialog);
