// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { ExportType } from "../../components/ExportPageFd/types/ExportPageFd.types";
import type { PageToSaveType } from "../../containers/FloatingControlButtons/types/FloatingControlButtons.types";
import type { DownloadResource } from "../../components/PageSettingsResources/types/PageSettingsResources.types";

export const getPage: (string) => PromiseResponse<mixed> = (id) => requestService
  .get(environment.endpoints.get.page.replace("{id}", id));

export const getVersionResources: (string) => PromiseResponse<mixed> = (id) => requestService
  .get(environment.endpoints.get.resourceList.replace("{versionId}", id));

export const createPage: (PageToSaveType) => PromiseResponse<mixed> = (data) => requestService
  .post(environment.endpoints.post.page, data);

export const editPage: (
  data: { data: PageToSaveType },
  id: string
) => PromiseResponse<mixed> = (data, id) => requestService
  .put(environment.endpoints.put.page.replace("{id}", id), data);

export const publishDocument: (
  data: { documentId: string }
) => PromiseResponse<mixed> = (data) => requestService
  .post(environment.endpoints.post.pageVersion, data);

type PageVersionPreviewType = { data: { data: { result: { url: string } } } };

export const getPageVersionPreview: (
  pageId: string,
  versionId: string
) => Promise<PageVersionPreviewType> = (pageId, versionId) => requestService
  .get(environment.endpoints.get.pageVersionPreview.replace("{documentId}", pageId), {
    params: { versionId },
  });

export const clonePage: ({
  pageId: string,
  pageName: string,
  accountId: string,
  alias?: string,
}) => PromiseResponse<mixed> = (data) => requestService
  .post(environment.endpoints.post.clonePage, { ...data });

export const exportPage: (
  format: ExportType,
  ids: Array<string>,
  documentType: string
) => PromiseResponse<mixed> = (format, ids, documentType) => requestService
  .post(environment.endpoints.post.exportPage,
    {
      format,
      ids,
      documentType,
    },
    {
      responseType: "blob",
    });

export const importPage = (
  file: File,
  type: string,
  activate: boolean,
  pageType: string = "pft"
): PromiseResponse<mixed> => {
  const body = new FormData();
  body.append("documentType", pageType);
  body.append("files", file);
  body.append("activate", JSON.stringify(activate));
  body.append("type", type);
  return requestService.post(environment.endpoints.post.importPage, body);
};

export const getJusResourcesContent: (
  accountId: string, versionId: string, resources: DownloadResource[]
) => PromiseResponse<mixed> = (accountId, versionId, resources) => requestService
  .post(environment.endpoints.post.downloadPageResources, { accountId, versionId, resources });