// @flow
import FileSaver from "file-saver";
import JSZip from "jszip";

export function transformToByteArray(resourceData: string) {
  const byteCharacters = atob(resourceData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return new Uint8Array(byteNumbers);
}
  
type Resource = {
  resourceId: string,
  resourceData: string,
  resourceName: string,
  extension: string,
  mimeType: string,
}

export function downloadSingleResource({ resourceName, resourceData }: Resource) {
  const byteArray = transformToByteArray(resourceData);
  const blob = new Blob([byteArray]);
  FileSaver.saveAs(blob, `${resourceName}`);
}
  
export function downloadAllResources(resources: Array<Resource>) {
  const zip = new JSZip();
  resources.forEach(({ resourceId, resourceData, extension }) => {
    const byteArray = transformToByteArray(resourceData);
    zip.file(`${resourceId}.${extension}`, byteArray);
  });

  zip.generateAsync({ type: "blob" })
    .then((content) => {
      FileSaver.saveAs(content, "files.zip");
    });
}