// @flow
import React from "react";
import {
  Typography,
  Grid,
  Avatar,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DeleteOutline } from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import type {
  RemovePageResourceAction,
  DownloadPageResourcesSaga,
} from "../../actions/pageResources";

type Props = {
  name: string,
  fileType: string,
  fileSize: string,
  previewUrl: string,
  base64URL: string,
  lastModifiedDate: string,
  onRemove: () => RemovePageResourceAction,
  onDownload: () => DownloadPageResourcesSaga,
  isResourceDownloading: boolean,
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 0",
  },
});

function PageSettingsResourceItem({
  name,
  fileType,
  fileSize,
  previewUrl,
  base64URL,
  lastModifiedDate,
  onRemove,
  onDownload,
  isResourceDownloading,
}: Props) {
  const classes = useStyles();
  const imagePath = (previewUrl) ? `https://${previewUrl}` : base64URL;
  const imageTypes = ["jpg", "jpeg", "png" , "gif", "svg", "icon", "webp"];



  return (
    <Grid data-testid="page-settings-resource-item" container mb={1} classes={{ root: classes.root }}>
      <Grid item xs={2} mr={1}>
        {imageTypes.includes(fileType) && (
          <Avatar variant="rounded" src={imagePath} sx={{
            maxWidth: "70px",
            width: "100%",
            height: "auto",
          }}/>
        )}
        {!imageTypes.includes(fileType) && (
          <Avatar variant="rounded" sx={{
            maxWidth: "70px",
            width: "100%",
          }}>
            {fileType}
          </Avatar>
        )}
      </Grid>
      <Grid item xs={5}>
        <Typography
          noWrap
          color="textPrimary"
          title="Resource name"
          variant="body2"
        >
          {name}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          noWrap
          color="textSecondary"
          title="Resource last modified date"
          variant="caption"
        >
          {lastModifiedDate}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          noWrap
          color="textSecondary"
          title="Resource size"
          variant="caption"
        >
          {fileSize}
        </Typography>
      </Grid>
      
      <Grid item xs={1}>
        <IconButton
          onClick={onDownload}
          title="Download resource"
          data-testid="page-settings-dropzone-container-resource-download-btn"
          disabled={isResourceDownloading}
        >
          <FileDownloadIcon />
        </IconButton>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          onClick={onRemove}
          title="Remove resource"
          data-testid="page-settings-dropzone-container-resource-remove-btn"
        >
          <DeleteOutline />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default PageSettingsResourceItem;
