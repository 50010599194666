// @flow
import type { StatelessFunctionalComponent, Node } from "react";
import type { Permission } from "@fas/ui-framework/lib/services/user";
import JusCan, {getHashValue} from "../../services/jusCan";

type Props = {
  permissions?: Array<Permission>,
  children: Node,
  renderNoAccess?: Node,
  accountId?: string,
};

const Can: StatelessFunctionalComponent<Props> = ({
  permissions = [],
  children = null,
  renderNoAccess = null,
  accountId = getHashValue(),
}: Props) => (JusCan(permissions, accountId) ? children : renderNoAccess);

export default Can;
