// @flow
/* eslint-disable import/max-dependencies */
import React, { useCallback } from "react";
import Dropzone from "react-dropzone";
import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ExpandMore } from "@mui/icons-material";
import { SetupCard } from "@fas/ui-core";
import PagesPageSettingsResourceItem from "./PageSettingsResourceItem";

import type { Resource } from "../../reducers/pageResources";
import type { DownloadResource } from "./types/PageSettingsResources.types";
import type {
  AddPageResourceAction,
  RemovePageResourceAction,
  RemoveAllPageResourceAction,
  ResourceFileFormats,
  DownloadPageResourcesSaga,
} from "../../actions/pageResources";

type Props = {
  resources: Array<any>,
  onAddResource: (content: Resource) => AddPageResourceAction,
  onRemoveResource: (index: number) => RemovePageResourceAction,
  onRemoveAllResource: () => RemoveAllPageResourceAction,
  onDownloadResources: (payload: DownloadResource[]) => DownloadPageResourcesSaga,
  accept: Array<ResourceFileFormats>,
  isResourceDownloading: boolean
}

const useStyles = makeStyles((theme) => ({
  pageSettingsDropzoneContainer: {
    width: "100%",
    border: "3px dotted",
    borderColor: theme.palette.borderColor,
    borderRadius: theme.borderRadius,
    textAlign: "center",
    cursor: "pointer",
    transition: "background .3s ease-in-out, border .3s ease-in-out, border-color .3s ease-in-out",
    "&:hover": {
      background: theme.palette.borderColor,
      border: "3px solid",
      borderColor: theme.palette.common.white,
    },
    "&:focus": {
      outline: "none",
    },
  },
  pageSettingsDropzoneContainerBrowseBtn: {
    padding: "20px 0",
    "& span": {
      color: theme.palette.text.titleColor,
    },
  },
}));

function PageSettingsResources({
  resources,
  onAddResource,
  onRemoveResource,
  onRemoveAllResource,
  onDownloadResources,
  isResourceDownloading,
  accept,
}: Props) {
  const classes = useStyles();

  const formatResourceSize = (size: number): string => {
    if (size === 0) return "0 Bytes";

    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(size) / Math.log(1024));
    const sizeFormated = parseFloat((size / 1024**i).toFixed(2));

    return `${sizeFormated} ${sizes[i]}`;
  };
  const dateTimeFormat = new Intl.DateTimeFormat("en", { year: "numeric", month: "short", day: "2-digit" });

  const onDrop = useCallback((acceptedFiles) => acceptedFiles.forEach((file) => {
    const reader: FileReader = new FileReader();
    const {
      name,
      size,
      lastModified,
    } = file;

    const sizeFormated = formatResourceSize(size);
    const typeFormated = name.split(".").slice(-1).pop();

    reader.onload = () => {
      const resource = {
        base64URL: reader.result,
        name,
        size: sizeFormated,
        type: typeFormated,
        lastModified,
      };
      onAddResource(resource);
    };
    reader.readAsDataURL(file);
  }), [onAddResource]);

  const downloadResourcesData = resources.map(({ name, resourceId }) => ({
    resourceName: name,
    resourceId,
  }));

  return (
    <SetupCard title="Files">
      <>
        <Dropzone
          onDrop={onDrop}
          accept={accept}
        >
          {({ getRootProps, getInputProps }) => (
            <Box mb={2} {...getRootProps({ className: classes.pageSettingsDropzoneContainer, "data-testid": "page-settings-dropzone-container" })}>
              <input {...getInputProps()} />
              <p className={classes.pageSettingsDropzoneContainerBrowseBtn}>
                Drag files here or
                &nbsp;
                <span>Browse</span>
              </p>
            </Box>
          )}
        </Dropzone>
        {
          resources.length !== 0 && (
            <Box mb={2} width={1}>
              <Accordion
                data-testid="page-settings-dropzone-container-collapse"
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="page-settings-dropzone-container-collapse-controls"
                  id="page-settings-dropzone-container-collapse-controls"
                  data-testid="page-settings-dropzone-container-collapse-controls"
                >
                  <Box ml={0.5} display="flex" flexDirection="column">
                    <Typography variant="caption">Uploaded files</Typography>
                    <Typography>
                      {resources.length}
                      &nbsp;
                      {resources.length > 1 ? "files" : "file"}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box data-testid="page-settings-resource-container" px={1} width={1}>
                    {resources.map((resource, index) => {
                      const {
                        name,
                        size,
                        type,
                        previewUrl,
                        base64URL,
                        lastModified,
                        resourceId,
                      } = resource;

                      const lastModifiedDate = new Date(lastModified);
                      const [{ value: month },, { value: day },, { value: year }] = dateTimeFormat
                        .formatToParts(lastModifiedDate);
                      const lastModifiedDateFormatted = `${month} ${day}, ${year}`;

                      return (
                        <PagesPageSettingsResourceItem
                          name={name}
                          fileType={type}
                          fileSize={size}
                          previewUrl={previewUrl}
                          base64URL={base64URL}
                          lastModifiedDate={lastModifiedDateFormatted}
                          onRemove={() => onRemoveResource(index)}
                          onDownload={() => onDownloadResources([{resourceName: name, resourceId}])}
                          key={`${name}_${type}_${lastModified}`}
                          isResourceDownloading={isResourceDownloading}
                        />
                      );
                    })}
                    {resources.length && (
                      <>
                        <Box align="right" mb={1}>
                          <Link
                            component="button"
                            variant="body1"
                            onClick={onRemoveAllResource}
                            data-testid="page-settings-resource-remove-all-btn"
                          >
                            Remove All
                          </Link>
                        </Box>
                        <Box align="right">
                          <Link
                            component="button"
                            variant="body1"
                            onClick={() => onDownloadResources(downloadResourcesData)}
                            data-testid="page-settings-resource-download-all-btn"
                            disabled={isResourceDownloading}
                          >
                            Download All
                          </Link>
                        </Box>
                      </>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          )
        }
      </>
    </SetupCard>
  );
}

export default PageSettingsResources;
