// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch } from "redux";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type {
  Action as NotificationsActions,
} from "@fas/ui-framework/lib/redux/actions/notifications";
import { tableReducer as tables, initTableState, type TableState } from "@fas/ui-framework/lib/redux/reducers/table";
import type {Order} from "@fas/ui-core/lib/Table/Table.types";
import {initState as initErrorsState, reducer as errors} from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import type {Actions as ErrorsActions} from "@fas/ui-framework/lib/redux/actions/errors/actions";
import type {State as ErrorsState} from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import loading, { initLoadingState } from "../../reducers/loading";
import type { State as LoadingState } from "../../reducers/loading";
import mainSaga from "./saga";

import {
  ACCOUNT_LIST,
  LINKING_LIST,
  PAGES_LIST,
  REDIRECT_LIST,
  SAVE_REDIRECT_LOADING,
  GET_REDIRECT_LOADING,
  SAVE_LINKING_LOADING,
  GET_LINKING_LOADING,
  ACCOUNT_MODULES_LOADING,
  GET_PAGE_LOADING,
  SAVE_PAGE_LOADING,
  GET_PAGE_LIST_LOADING,
  GET_ACCOUNT_LOADING,
  SAVE_ACCOUNT_LOADING,
  PAGE_EXPORTING_LOADING,
  PAGE_IMPORTING_LOADING,
  PAGE_CLONING_LOADING,
  HEIGHT_LOADING,
  GET_PAGE_VERSION_PREVIEW_LOADING,
} from "../../helpers/constants";
import backupReducer from "../../reducers/backup/reducer";

import accountModules, { initAccountModulesState } from "../../reducers/accountModules";
import type { State as AccountModulesState } from "../../reducers/accountModules";
import accountInfo from "../../reducers/accountInfo/reducer";
import accountUsers from "../../reducers/accountUsers/reducer";
import { initAccountGeneralInfoState } from "../../reducers/accountInfo";
import type {State as GeneralInfoState} from "../../reducers/accountInfo";
import { initSetupAccountUsersState } from "../../reducers/accountUsers";
import type {State as SetupUsersState} from "../../reducers/accountUsers";

import pagesList, { initPagesState } from "../../reducers/pages";
import type { State as PagesListState } from "../../reducers/pages";
import pageInfo, { initPageInfoState } from "../../reducers/pageInfo";
import type { State as PageInfoState } from "../../reducers/pageInfo";
import pageVersions, { initPageVersionsState } from "../../reducers/pageVersions";
import type { State as PageVersionsState } from "../../reducers/pageVersions";
import pageModifiers, { initPageModifiersState } from "../../reducers/pageModifiers";
import type { State as PageModifiersState } from "../../reducers/pageModifiers";
import pageResources, { initPageResourcesState } from "../../reducers/pageResources";
import type { State as PageResourcesState } from "../../reducers/pageResources";
import clonePage, { initClonePageState } from "../../reducers/clonePage";
import type { State as ClonePageState } from "../../reducers/clonePage";
import exportPage, { initExportPageState } from "../../reducers/exportPage";
import type { State as ExportPageState } from "../../reducers/exportPage";
import importPage, { initImportPageState } from "../../reducers/importPage";
import type { State as ImportPageState } from "../../reducers/importPage";
import modifierForm, { initModifierFormState } from "../../reducers/modifierForm";
import type { State as ModifierFormState } from "../../reducers/modifierForm";

import redirect, { initRedirectInfoState } from "../../reducers/redirectInfo";
import type { State as RedirectInfoState } from "../../reducers/redirectInfo";

import linking, { initLinkingInfoState } from "../../reducers/linkingInfo";
import type { State as LinkingInfoState } from "../../reducers/linkingInfo";

import type { Actions as LoadingActions, AccountListLoadingTypes as LoadingTypes } from "../../actions/loading";
import type { Actions as BackupActions } from "../../actions/backup";

import type { Actions as AccountListActions } from "../../actions/accountList";
import type { Actions as GeneralInfoActions} from "../../actions/accountInfo";
import type { Actions as SetupUsersActions} from "../../actions/accountUsers";

import type { Actions as PagesActions } from "../../actions/pages";
import type { Actions as PageInfoActions } from "../../actions/pageInfo";
import type { Actions as PageVersionsActions } from "../../actions/pageVersions";
import type { Actions as PageModifiersActions } from "../../actions/pageModifiers";
import type { Actions as PageResourcesActions } from "../../actions/pageResources";
import type { Actions as PageActions } from "../../actions/pageActions";

import type { Actions as RedirectListActions } from "../../actions/redirectList";
import type { Actions as RedirectInfoActions } from "../../actions/redirectInfo";

import type { Actions as LinkingActions } from "../../actions/linkingList";

export type State = $ReadOnly<{|
  loading: LoadingState<string>,
  tables: TableState,
  notifications: NotificationsState,
  errors: ErrorsState,

  account: {
    accountInfo: GeneralInfoState,
    accountUsers: SetupUsersState,
    accountModules: AccountModulesState,
  },
  page: {
    pagesList: PagesListState,
    pageInfo: PageInfoState,
    pageVersions: PageVersionsState,
    pageModifiers: PageModifiersState,
    pageResources: PageResourcesState,
    clonePage: ClonePageState,
    exportPage: ExportPageState,
    importPage: ImportPageState,
    modifierForm: ModifierFormState,
  },  
  redirect: RedirectInfoState,

  linking: LinkingInfoState,
|}>

export type Filters = {[key: string]: string};
export type Sorting = {[key: string]: Order};

function mapAppToState(): State {
  return {
    loading: initLoadingState({ 
      [ACCOUNT_LIST]: false,
      [LINKING_LIST]: false,
      [PAGES_LIST]: false,
      [REDIRECT_LIST]: false,
      [ACCOUNT_MODULES_LOADING]: false,
      [GET_PAGE_LOADING]: false,
      [SAVE_PAGE_LOADING]: false,
      [GET_PAGE_LIST_LOADING]: false,
      [GET_LINKING_LOADING]: false,
      [SAVE_LINKING_LOADING]: false,
      [GET_REDIRECT_LOADING]: false,
      [SAVE_REDIRECT_LOADING]: false,
      [GET_ACCOUNT_LOADING]: false,
      [SAVE_ACCOUNT_LOADING]: false,
      [PAGE_EXPORTING_LOADING]: false,
      [PAGE_IMPORTING_LOADING]: false,
      [HEIGHT_LOADING]: false,
      [GET_PAGE_VERSION_PREVIEW_LOADING]: false,
      [PAGE_CLONING_LOADING]: false,
    }),
    tables: initTableState({
      [ACCOUNT_LIST]: { pageSize: 20 },
      [LINKING_LIST]: { pageSize: 20 },
      [PAGES_LIST]: { pageSize: 20 },
      [REDIRECT_LIST]: { pageSize: 20 },
    }),
    notifications: initNotificationsState(),
    errors: initErrorsState(),

    account: {
      accountInfo: initAccountGeneralInfoState(),
      accountUsers: initSetupAccountUsersState(),
      accountModules: initAccountModulesState(),
    },
    
    page: {
      pagesList: initPagesState(),
      pageInfo: initPageInfoState(),
      pageVersions: initPageVersionsState(),
      pageModifiers: initPageModifiersState(),
      pageResources: initPageResourcesState(),
      clonePage: initClonePageState(),
      exportPage: initExportPageState(),
      importPage: initImportPageState(),      
      modifierForm: initModifierFormState(),
    },

    redirect: initRedirectInfoState(),
    linking: initLinkingInfoState(),
  };
}

export const defaultState = mapAppToState();

type Actions =
  LoadingActions<LoadingTypes>
  | AccountListActions
  | NotificationsActions
  | GeneralInfoActions
  | SetupUsersActions
  | LinkingActions
  | BackupActions
  | PagesActions
  | PageActions
  | PageInfoActions
  | PageVersionsActions
  | PageModifiersActions
  | PageResourcesActions
  | RedirectListActions
  | RedirectInfoActions
  | ErrorsActions;

export const accountBackupStore: string[] = ["account"];
export const pageBackupStore: string[] = ["page"];
export const redirectBackupStore: string[] = ["redirect"];
export const linkingBackupStore: string[] = ["linking"];

const accountReducer = combineReducers({
  accountInfo,
  accountUsers,
  accountModules,
});

const pageReducer = combineReducers({
  clonePage,
  exportPage,
  importPage,
  pageInfo,
  pageVersions,
  pageModifiers,
  pageResources,
  pagesList,
  modifierForm,
});

const rootReducer = combineReducers({
  loading,
  tables,
  notifications,
  errors,
  account: accountReducer,
  page: pageReducer,
  linking,
  redirect,
});

const sagaMiddleware = createSagaMiddleware();

export default () => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store = createStore<State, Actions, Dispatch<Actions>>(
    backupReducer(rootReducer),
    mapAppToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );
  sagaMiddleware.run(mainSaga);
  return store;
};
