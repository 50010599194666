// @flow
import joi from "joi";

// eslint-disable-next-line import/prefer-default-export
export const schema = joi
  .object({
    redirectInfo: joi.object({
      pageUrl: joi
        .string()
        .required()
        .min(1)
        .max(255)
        .label("Page URL"),
      statusCode: joi
        .string()
        .required()
        .label("Status Code"),
      redirectUrl: joi
        .string()
        .required()
        .min(1)
        .max(255)
        .label("Redirect URL"),
    }),
  })
  .options({ abortEarly: false, allowUnknown: true });